import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { DateFormatUTC } from '@constants/global-constants/constants';
import { PRODUCTION_ENV } from '@constants/RouteConstants';
import { config } from '@environment/environment';
import moment from 'moment-timezone';
import { RecipientDataDto } from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';

export const capitalize = (str: string): string => {
  return str?.length ? str.charAt(0).toUpperCase() + str.slice(1) : '';
};

export const capitalizeWords = (input: string): string => {
  return input
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const joinArrayElementsWithComma = (arr: string[]): string => {
  if (arr.length < 2) {
    return arr[0];
  }
  const result =
    arr.slice(0, -1).join(', ') +
    (arr.length > 1 ? ', ' : '') +
    arr[arr.length - 1];
  return result;
};

export const generateRandomTableName = (prefixValue: string): string => {
  const randomSuffix = Math.random().toString(36).substring(2, 10);
  return `${prefixValue}-${randomSuffix}`;
};

export const getRecipientFromEmailRecipientsListByType = (
  recipientsList: RecipientDataDto[],
  recipientType: string,
): string =>
  recipientsList
    .filter(({ type }) => type?.toLowerCase() === recipientType)
    .map(({ email }) => email)
    .join(', ');

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const isProductionEnvironment = (): boolean => {
  if (config.BUILD_ENV !== PRODUCTION_ENV) {
    return false;
  }
  return true;
};

export const formatDateAndTimeWithTimeZone = (
  date: Date | null | string,
): string => {
  const momentDate = moment.utc(date);

  if (!momentDate.isValid()) {
    return '';
  }

  const localDate = momentDate.local();

  const timeZoneAbbreviation = moment().tz(moment.tz.guess()).format('z');

  return `${localDate.format(DateFormatUTC)} ${timeZoneAbbreviation}`;
};

export const checkIsNonEmailBasedTask = (taskCategory: string): boolean => {
  const isNonEmailBasedTaskCategory = [
    AdminConstants.QUEUE.DB_QUERY_SHARED_SERVICES,
    AdminConstants.QUEUE.DB_QUERY_NSA,
    AdminConstants.QUEUE.DB_QUERY_STATE,
    AdminConstants.QUEUE.DB_QUERY_DUAL_INELIGIBILITY,
    AdminConstants.QUEUE.DATA_EXCEPTIONS_SHARED_SERVICES,
    AdminConstants.QUEUE.DATA_EXCEPTIONS_STATE_TEAM,
    AdminConstants.QUEUE.DATA_EXCEPTIONS_ARBIT_TEAM,
  ].includes(taskCategory);

  return isNonEmailBasedTaskCategory;
};
