import { ReactElement, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  BodyContainer,
  CustomImg,
  LoadingGridForProfilePic,
  LogoContainer,
  MainContainer,
  MenuItemName,
  NavBarMenuItem,
  NavBarMenuItemsContainer,
  ProfileName,
  ProfilePictureAndWelcomeTextContainer,
  ProfilePictureContainer,
  TopNavBarContainer,
  WelcomeText,
  WelcomeTextAndProfileNameContainer,
} from './TopNavBarStyle';
import { Avatar } from '@mui/material';
import { UserConstants } from '@constants/user-constants/UserConstants';
import { TopNavBarMenuItemType } from 'src/types/userTypes';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import AppLogo from '@images/AppLogo.png';
import Loader from '@components/loaders/Loader';
import { t } from 'i18next';
import {
  setIsProfileDetailsLoading,
  setIsProfilePictureLoading,
  setProfileData,
  setProfilePicture,
} from '@modules/home/state/profile-details/ProfileDetailsSlice';
import { setQueueSearchAndFilterOptions } from '@modules/home/state/queue/QueueSlice';
import { RootState } from 'src/store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { enqueueSnackbar } from 'notistack';
import {
  getProfileDetails,
  getProfilePicture,
} from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';

const topNavBarMenuItemsList: TopNavBarMenuItemType[] = [
  {
    name: UserConstants.USER_NAV_BAR.QUEUE,
    isActive: true,
    navigationUrl: PrivateNavigationRoutes.User.ChildRoutes.Queue.Path,
  },
  {
    name: UserConstants.USER_NAV_BAR.HISTORY,
    isActive: false,
    navigationUrl: PrivateNavigationRoutes.User.ChildRoutes.History.Path,
  },
];

const TopNavBar = (): ReactElement => {
  const [topNavBarMenuItems, setTopNavBarMenuItems] = useState(
    topNavBarMenuItemsList,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const userDetails = useSelector((state: RootState) => state.profile);
  const {
    id,
    profilePicId,
    profilePic,
    name,
    isProfilePictureLoading,
    isProfileDetailsLoading,
  } = useSelector((state: RootState) => state.profile);

  const getProfilePictureById = async (userId: string | null) => {
    if (userId) {
      dispatch(setIsProfilePictureLoading(true));
      try {
        const getProfilePicResp = await getProfilePicture(id);
        const { data } = getProfilePicResp;
        if (data?.mimeType?.length > 0 && data?.content?.data?.length > 0) {
          const profilePicData = data.content.data;
          const blobImg = new Blob([new Uint8Array(profilePicData)], {
            type: data.mimeType,
          });
          const profilePicUrl = URL.createObjectURL(blobImg);
          dispatch(setProfilePicture(profilePicUrl));
        } else {
          const errMessage = t(
            'ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_NOT_FOUND',
          );
          enqueueSnackbar(errMessage, SnackBarConfig.getError());
        }
      } catch (error) {
        const errMessage = getErrorMessage(error);
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
      } finally {
        dispatch(setIsProfilePictureLoading(false));
      }
    }
  };
  const init = async () => {
    dispatch(setIsProfileDetailsLoading(true));
    try {
      const response = await getProfileDetails();
      const { data } = response;
      dispatch(setProfileData(data));
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      dispatch(setIsProfileDetailsLoading(false));
    }
  };

  useEffect(() => {
    if (!id) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profilePicId) {
      getProfilePictureById(profilePicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePicId]);

  const setClickedTopNavBarMenuItemActive = (topNavBarMenuItemName: string) => {
    const newTopNavBarMenuItemsList = topNavBarMenuItems.map(
      (topNavBarMenuItem: TopNavBarMenuItemType) => ({
        ...topNavBarMenuItem,
        isActive:
          topNavBarMenuItem.name?.toLowerCase() ===
          topNavBarMenuItemName?.toLowerCase(),
      }),
    );

    setTopNavBarMenuItems(newTopNavBarMenuItemsList);
  };

  useEffect(() => {
    const currentPath = location.pathname.split('/')[2];

    setClickedTopNavBarMenuItemActive(currentPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const renderProfilePictureAndWelcomeText = () => (
    <ProfilePictureAndWelcomeTextContainer>
      <WelcomeTextAndProfileNameContainer>
        <WelcomeText>{UserConstants.USER_NAV_BAR.WELCOME}</WelcomeText>
        <ProfileName>
          {isProfileDetailsLoading ? <Loader size={20} /> : name}
        </ProfileName>
      </WelcomeTextAndProfileNameContainer>
      <ProfilePictureContainer
        onClick={() => {
          setClickedTopNavBarMenuItemActive(
            PrivateNavigationRoutes.User.ChildRoutes.Profile.Path,
          );
          navigate(PrivateNavigationRoutes.User.ChildRoutes.Profile.Path);
        }}
      >
        {isProfilePictureLoading ? (
          <LoadingGridForProfilePic>
            <Loader size={30} />
          </LoadingGridForProfilePic>
        ) : (
          <Avatar
            alt=""
            src={profilePic}
            sx={{ width: '3rem', height: '3rem' }}
          />
        )}
      </ProfilePictureContainer>
    </ProfilePictureAndWelcomeTextContainer>
  );

  const renderTopNavBarMenuItem = (
    topNavBarMenuItem: TopNavBarMenuItemType,
    index: number,
  ) => {
    return (
      <NavBarMenuItem
        key={`${topNavBarMenuItem}-${index}`}
        isActive={topNavBarMenuItem.isActive}
        onClick={() => {
          setClickedTopNavBarMenuItemActive(topNavBarMenuItem.name);
          dispatch(setQueueSearchAndFilterOptions({ data: {} }));
          navigate(topNavBarMenuItem.navigationUrl);
        }}
      >
        <MenuItemName isActive={topNavBarMenuItem.isActive}>
          {topNavBarMenuItem.name}
        </MenuItemName>
      </NavBarMenuItem>
    );
  };

  const isQueueDetailsScreen = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return pathParts.includes('details');
  };
  return (
    <MainContainer>
      {!isQueueDetailsScreen() && (
        <TopNavBarContainer>
          {/* Logo section*/}
          <LogoContainer
            onClick={() => navigate(PrivateNavigationRoutes.Home.Path)}
          >
            <CustomImg src={AppLogo} alt="HaloMd logo" />
          </LogoContainer>

          {/* Menu items section */}
          <NavBarMenuItemsContainer>
            {topNavBarMenuItems.map(
              (topNavBarMenuItem: TopNavBarMenuItemType, index: number) =>
                renderTopNavBarMenuItem(topNavBarMenuItem, index),
            )}
          </NavBarMenuItemsContainer>

          {/* Profile section */}
          {renderProfilePictureAndWelcomeText()}
        </TopNavBarContainer>
      )}
      <BodyContainer>
        <Outlet />
      </BodyContainer>
    </MainContainer>
  );
};

export default TopNavBar;
