import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  AvatarContainer,
  BackButton,
  BodyCardsContainer,
  ButtonsContainer,
  CancelButtonStyled,
  CheckboxStyled,
  CustomImg,
  DividerStyled,
  EditImg,
  Email,
  InfoContainer,
  InfoSubContainer,
  InfoTitle,
  InfoValue,
  LeftSectionContainer,
  LoaderContainer,
  LoadingGridForProfilePic,
  ProfileCardContainer,
  ProfileContainer,
  ProfileInfoContainer,
  ProfileTitle,
  ProfileTitleForLoader,
  QueueAssignmentOptionContainer,
  QueueAssignmentsContainer,
  QueueAssignmentsOptionsContainer,
  QueueAssignmentsTitle,
  RightSectionContainer,
  SaveButtonDisabledStyled,
  SaveButtonStyled,
  SelectStateListContainer,
  SelectStateNsaDropdownContainer,
  SelectStateNsaDropdownTitle,
  SelectStateNsaDropdownWrapper,
  SetTimeOffContainer,
  StateCodeChipsContainer,
  StateNsaContainer,
  StateNsaTitle,
  StyledCustomChip,
  SubLoaderContainer,
  SubLoaderContainerForButtons,
  TabFilterContainer,
  TitleAndRoleContainer,
  TopBarButtonsContainer,
  UserSettingsContainer,
  UserSettingsTopBar,
} from './UsersSettingsStyle';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { StateNsaAllStateEnum } from 'src/enums/admin-enums/AdminEnums';
import { useDebouncedCallback } from 'use-debounce';
import { SEARCH_DEBOUNCE_DELAY_IN_MS } from '@constants/global-constants/constants';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { GetAllRolesDataRow } from 'src/services/service-handlers/private/roles-handlers/RolesServiceMapper';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import {
  GetAllStatesDataRow,
  GetAllStatesQueryParam,
} from 'src/services/service-handlers/private/ref-data-handlers/RefDataServiceMapper';
import {
  GetAllAssignmentLevelsDataRow,
  GetAllTaskCategoriesDataRow,
} from 'src/services/service-handlers/private/task-categories-handlers/TaskCategoriesServiceMapper';
import {
  GetUserProfileDataDto,
  GetUserProfileQueryParam,
  ICategoryOption,
  RoleIdType,
  RoleOptionPayloadType,
  StateIdType,
  TimeOffsType,
  // TimeOffsType,
  UpdateUserProfilePayloadDto,
} from 'src/services/service-handlers/private/users-handlers/UsersServiceMapper';
import { produce } from 'immer';
import {
  getUserProfileById,
  updateUserProfile,
} from 'src/services/service-handlers/private/users-handlers/UsersPrivateService';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { getAllStates } from 'src/services/service-handlers/private/ref-data-handlers/RefDataPrivateService';
import {
  Avatar,
  FormControlLabel,
  FormGroup,
  TextField,
  Tooltip,
} from '@mui/material';
import { LabelStyled } from '@components/select-input/select-text-input/SelectTextInputStyle';
import SelectSearchInput from '@components/select-input/select-search-input/SelectSearchInput';
import ArrowSolidIcon from '@images/ArrowSolid.svg';
import CrossIcon from '@assets/images/Close.svg';
import EditIcon from '@assets/images/Edit.svg';
import SelectTextInput from '@components/select-input/select-text-input/SelectTextInput';
import {
  getAllAssignmentLevels,
  getAllTaskCategories,
} from 'src/services/service-handlers/private/task-categories-handlers/TaskCategoriesPrivateService';
import TabFilter from '@components/tabs/tab-filter/TabFilter';
import { joinArrayElementsWithComma } from '@utils/miscellaneousUtils';
import { getErrorMessage } from '@utils/ErrorUtils';
import Loader from '@components/loaders/Loader';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import MarkAsActiveOrInactiveUserConfirmationDialog from '../mark-as-active-or-inactive-user-confirmation-dialog/MarkAsActiveOrInactiveUserConfirmationDialog';
import UpdateTitleAndRoleDialog from './update-title-and-role-dialog/UpdateTitleAndRoleDialog';
import TimeOff from '@components/timeOff/TimeOff';
import {
  addNewTimeOff,
  deleteTimeOff,
  getProfilePicture,
  updateTimeOff,
} from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';
import moment from 'moment';
import { formatDateWithoutTime } from '@modules/home/utils/Utils';
import { SetProfilePicsUrlsWithLoaderFlagByIdPayloadType } from '@modules/home/state/queue/QueueTypes';
import { setProfilePicsUrlsWithLoaderFlagById } from '@modules/home/state/queue/QueueSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';

interface IStateCode {
  id: string;
  stateCode: string;
}

const mapDropdownOptionToDropdownOptionsType = (
  optionsList: GetAllRolesDataRow[],
) => {
  return optionsList.map(option => ({
    value: option.id,
    label: option.label,
  }));
};

const selectStateNsaDropdownOption = [
  {
    value: StateNsaAllStateEnum.STATE,
    label: AdminConstants.USERS.STATE,
  },
  {
    value: StateNsaAllStateEnum.NSA,
    label: AdminConstants.USERS.NSA,
  },
];

interface IEditUserSettingsFormState {
  stateNsa: string;
  isAllState: boolean;
  searchStateInput: string;
  states: string[];
  categories: ICategoryOption[];
  selectedStatesCodeList: IStateCode[];
  title: {
    id: string;
    label: string;
  };
  roles: RoleIdType[];
}

const initialEditUserSettingsFormState: IEditUserSettingsFormState = {
  stateNsa: '',
  isAllState: false,
  searchStateInput: '',
  states: [],
  categories: [],
  selectedStatesCodeList: [],
  title: {
    id: '',
    label: '',
  },
  roles: [],
};

export interface IUpdatedTitleAndRole {
  title: {
    id: string;
    label: string;
  };
  roles: RoleIdType[];
}

const initialUpdatedTitleAndRole: IUpdatedTitleAndRole = {
  title: {
    id: '',
    label: '',
  },
  roles: [],
};

const convertRoleLabelsToCommaSeparatedStringFromRoleIds = (
  roleIds: RoleIdType[],
) => {
  const roleLabelsList = roleIds?.map(role => role.roleLabel);
  const roleLabels = joinArrayElementsWithComma(
    roleLabelsList?.length ? roleLabelsList : [],
  );
  return roleLabels;
};

const UsersSettings = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const { control } = useForm({});

  const navigate = useNavigate();

  const { userId = '' } = useParams();

  const { state } = useLocation();

  const dispatch = useDispatch();

  const { profilePicsUrlsWithLoaderFlagById } = useSelector(
    (reduxState: RootState) => reduxState.queue,
  );

  const [userProfileByID, setUserProfileByID] = useState<GetUserProfileDataDto>(
    {
      id: '',
      name: '',
      phone: '',
      email: '',
      contactEmail: '',
      provider: '',
      profilePicId: '',
      status: '',
      title: {
        id: '',
        label: '',
      },
      roles: [],
      stateNsa: '',
      states: [],
      taskCategories: [],
      timeOffs: [],
    },
  );

  const getProfilePictureById = async () => {
    const profilePictureId = userProfileByID?.profilePicId ?? '';
    if (userId.length > 0 && profilePictureId.length > 0) {
      if (profilePicsUrlsWithLoaderFlagById[userId]) {
        return profilePicsUrlsWithLoaderFlagById[userId];
      } else {
        const initialProfilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
          {
            userId,
            profilePicUrl: '',
            isProfilePicLoading: true,
          };
        dispatch(
          setProfilePicsUrlsWithLoaderFlagById(
            initialProfilePicsUrlsWithLoaderFlagByIdPayload,
          ),
        );

        try {
          const getProfilePicResp = await getProfilePicture(userId);
          const { data } = getProfilePicResp;
          if (data?.mimeType?.length > 0 && data?.content?.data?.length > 0) {
            const profilePicData = data.content.data;
            const blobImg = new Blob([new Uint8Array(profilePicData)], {
              type: data.mimeType,
            });
            const profilePicUrl = URL.createObjectURL(blobImg);
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl,
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return profilePicUrl;
          } else {
            const errMessage = t(
              'ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_NOT_FOUND',
            );
            enqueueSnackbar(errMessage, SnackBarConfig.getError());
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl: '',
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return '';
          }
        } catch (error) {
          const errMessage = getErrorMessage(error);
          enqueueSnackbar(errMessage, SnackBarConfig.getError());
          const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
            {
              userId,
              profilePicUrl: '',
              isProfilePicLoading: false,
            };
          dispatch(
            setProfilePicsUrlsWithLoaderFlagById(
              profilePicsUrlsWithLoaderFlagByIdPayload,
            ),
          );
          return '';
        }
      }
    }
    return '';
  };

  const [showTitleAndRoleEditDialog, setShowTitleAndRoleEditDialog] =
    useState(false);

  const [editUserSettingsFormState, setEditUserSettingsFormState] =
    useState<IEditUserSettingsFormState>(initialEditUserSettingsFormState);

  const [apiLoadersState, setApiLoadersState] = useState({
    isFetchingUserProfileById: false,
    isFetchingAllRoles: false,
    isFetchingAllTitles: false,
    isFetchingStates: false,
    isFetchingAllTaskCategoriesList: false,
    isFetchingAllAssignmentLevels: false,
    isInitialFetchInProgress: false,
    isUpdatingUserProfile: false,
    isMarkingAsActive: false,
    isAddingNewTimeOff: false,
    isUpdatingTimeOff: false,
    isDeletingTimeOff: false,
  });

  const [
    showMarkAsActiveConfirmationDialog,
    setShowMarkAsActiveConfirmationDialog,
  ] = useState(false);

  const [updatedTitleAndRole, setUpdatedTitleAndRole] =
    useState<IUpdatedTitleAndRole>(initialUpdatedTitleAndRole);

  const [permittedStateOptionsList, setPermittedStateOptionsList] = useState<
    GetAllStatesDataRow[]
  >([]);

  const [allTaskCategoriesList, setAllTaskCategoriesList] = useState<
    GetAllTaskCategoriesDataRow[]
  >([]);

  const [allAssignmentsLevelsList, setAllAssignmentsLevelsList] = useState<
    GetAllAssignmentLevelsDataRow[]
  >([]);

  const [isOptionDropdownOpen, setIsOptionDropdownOpen] =
    useState<boolean>(false);

  const [
    isSaveEditedUserSettingsButtonDisabled,
    setIsSaveEditedUserSettingsButtonDisabled,
  ] = useState(false);

  const [
    isPrePopulatingStateOptionsFinished,
    setIsPrePopulatingStateOptionsFinished,
  ] = useState(false);

  // API section starts
  const addNewTimeOffForUser = async (startDate: Date, endDate: Date) => {
    setApiLoadersState(
      produce(draft => {
        draft.isAddingNewTimeOff = true;
      }),
    );
    const startDateWithoutTime = formatDateWithoutTime(startDate);
    const endDateWithoutTime = formatDateWithoutTime(endDate);
    if (moment(startDateWithoutTime).isSameOrBefore(endDateWithoutTime)) {
      try {
        const addNewTimeOffPayload = {
          startDate: formatDateWithoutTime(startDate),
          endDate: formatDateWithoutTime(endDate),
        };

        const addNewTimeOffApiResponse = await addNewTimeOff(
          addNewTimeOffPayload,
          userId,
        );
        const { data } = addNewTimeOffApiResponse;
        const timeOffId = data.id;
        const currentTimeOffs = Array.isArray(userProfileByID.timeOffs)
          ? userProfileByID.timeOffs
          : [];

        const newTimeOffsList: TimeOffsType[] = [
          ...currentTimeOffs,
          {
            id: timeOffId,
            startDate: formatDateWithoutTime(startDate),
            endDate: formatDateWithoutTime(endDate),
          },
        ];

        setUserProfileByID(
          produce(draft => {
            draft.timeOffs = [...newTimeOffsList];
          }),
        );
        const successMessage =
          addNewTimeOffApiResponse?.data?.message ??
          t('ADMIN.USERS_TAB.USER_SETTINGS.DEFAULT_ADDED_NEW_TIME_OFF');
        enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
      } catch (error) {
        const errMessage = getErrorMessage(error);
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
      } finally {
        setApiLoadersState(
          produce(draft => {
            draft.isAddingNewTimeOff = false;
          }),
        );
      }
    } else {
      const ErrorMessage = t(
        'ADMIN.USERS_TAB.ADD_USER_FORM.TIME_OFF_VALIDATIONS.END_DATE_SHOULD_GREATER',
      );
      enqueueSnackbar(ErrorMessage, SnackBarConfig.getError());
    }
  };

  const updateTimeOffForUser = async (
    startDate: Date,
    endDate: Date,
    editIndex: number,
  ) => {
    setApiLoadersState(
      produce(draft => {
        draft.isUpdatingTimeOff = true;
      }),
    );
    if (editIndex !== null) {
      const timeOffToUpdate = userProfileByID.timeOffs[editIndex];
      const currentStartDate = formatDateWithoutTime(
        new Date(timeOffToUpdate.startDate),
      );
      const currentEndDate = formatDateWithoutTime(
        new Date(timeOffToUpdate.endDate),
      );
      const currentDateWithoutTime = formatDateWithoutTime(new Date());

      const startDateWithoutTime = formatDateWithoutTime(startDate);
      const endDateWithoutTime = formatDateWithoutTime(endDate);

      if (
        moment(startDateWithoutTime).isSame(currentStartDate) &&
        moment(endDateWithoutTime).isAfter(currentDateWithoutTime) &&
        !moment(endDateWithoutTime).isSame(currentEndDate)
      ) {
        try {
          const updateTimeOffPayload = {
            startDate: formatDateWithoutTime(startDate),
            endDate: formatDateWithoutTime(endDate),
          };

          // Update the time off entry
          const updateTimeOffApiResponse = await updateTimeOff(
            updateTimeOffPayload,
            userId,
            userProfileByID.timeOffs[editIndex].id,
          );
          const updatedTimeOffs = [...userProfileByID.timeOffs];
          updatedTimeOffs[editIndex] = {
            ...updatedTimeOffs[editIndex],
            startDate: formatDateWithoutTime(startDate),
            endDate: formatDateWithoutTime(endDate),
          };
          setUserProfileByID(
            produce(draft => {
              draft.timeOffs = [...updatedTimeOffs];
            }),
          );
          // Show success message
          const successMessage =
            updateTimeOffApiResponse?.data?.message ??
            t('ADMIN.USERS_TAB.USER_SETTINGS.DEFAULT_UPDATED_TIME_OFF');
          enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
        } catch (error) {
          const ErrorMessage = t(
            'ADMIN.USERS_TAB.ADD_USER_FORM.TIME_OFF_VALIDATIONS.START_DATE_CANNOT_BE_CHANGED',
          );
          enqueueSnackbar(ErrorMessage, SnackBarConfig.getError());
        } finally {
          setApiLoadersState(
            produce(draft => {
              draft.isUpdatingTimeOff = false;
            }),
          );
        }
      } else {
        try {
          const updateTimeOffPayload = {
            startDate: formatDateWithoutTime(startDate),
            endDate: formatDateWithoutTime(endDate),
          };
          const updateTimeOffApiResponse = await updateTimeOff(
            updateTimeOffPayload,
            userId,
            userProfileByID.timeOffs[editIndex].id,
          );
          const updatedTimeOffs = [...userProfileByID.timeOffs];
          updatedTimeOffs[editIndex] = {
            ...updatedTimeOffs[editIndex],
            startDate: formatDateWithoutTime(startDate),
            endDate: formatDateWithoutTime(endDate),
          };
          setUserProfileByID(
            produce(draft => {
              draft.timeOffs = [...updatedTimeOffs];
            }),
          );
          const successMessage =
            updateTimeOffApiResponse?.data?.message ??
            t('ADMIN.USERS_TAB.USER_SETTINGS.DEFAULT_UPDATED_TIME_OFF');
          enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
        } catch (error) {
          const ErrorMessage = t(
            'ADMIN.USERS_TAB.ADD_USER_FORM.TIME_OFF_VALIDATIONS.START_DATE_CANNOT_BE_CHANGED',
          );
          enqueueSnackbar(ErrorMessage, SnackBarConfig.getError());
        } finally {
          setApiLoadersState(
            produce(draft => {
              draft.isUpdatingTimeOff = false;
            }),
          );
        }
      }
    }
  };

  const deleteTimeOffForUser = async (
    index: number,
    ExistingUserId: string,
    timeOffId: string,
  ) => {
    setApiLoadersState(
      produce(draft => {
        draft.isDeletingTimeOff = true;
      }),
    );
    try {
      const deleteTimeOffApiResponse = await deleteTimeOff(
        ExistingUserId,
        timeOffId,
      );
      const updatedTimeOffs = userProfileByID.timeOffs.filter(
        (_, i) => i !== index,
      );
      setUserProfileByID(
        produce(draft => {
          draft.timeOffs = [...updatedTimeOffs];
        }),
      );
      const successMessage =
        deleteTimeOffApiResponse?.data?.message ??
        t('ADMIN.USERS_TAB.USER_SETTINGS.DEFAULT_DELETED_TIME_OFF');
      enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
    } catch (errorResponse: any) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isDeletingTimeOff = false;
        }),
      );
    }
  };

  const fetchUserProfileById = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingUserProfileById = true;
      }),
    );
    try {
      const payload: GetUserProfileQueryParam = {
        isActive: state?.isUserActive ?? true,
      };

      const getUserProfileByIdResponse = await getUserProfileById(
        userId,
        payload,
      );
      const userProfileByIdData = getUserProfileByIdResponse.data;

      setUserProfileByID(userProfileByIdData);
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingUserProfileById = false;
        }),
      );
    }
  };

  const fetchAllTaskCategories = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingAllTaskCategoriesList = true;
      }),
    );
    try {
      const getAllTaskCategoriesResponse = await getAllTaskCategories();
      const allTaskCategories = getAllTaskCategoriesResponse.data.rows;
      setAllTaskCategoriesList([...allTaskCategories]);
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingAllTaskCategoriesList = false;
        }),
      );
    }
  };

  const fetchAllAssignmentLevels = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingAllAssignmentLevels = true;
      }),
    );
    try {
      const getAllAssignmentLevelsResponse = await getAllAssignmentLevels();
      const allAssignmentLevels = getAllAssignmentLevelsResponse.data.rows;
      setAllAssignmentsLevelsList([...allAssignmentLevels]);
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingAllAssignmentLevels = false;
        }),
      );
    }
  };

  const fetchStatesList = async (newSearchStateInputValue = '') => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingStates = true;
      }),
    );
    try {
      const payload: GetAllStatesQueryParam = {
        label: newSearchStateInputValue,
      };
      const getAllStatesListResponse = await getAllStates(payload);
      const allStatesList = getAllStatesListResponse.data.rows;
      const allStatesOption: GetAllStatesDataRow = {
        id: StateNsaAllStateEnum.ALL_STATE,
        label: AdminConstants.USERS.ALL_CAPITALIZED,
        nameId: AdminConstants.USERS.ALL,
        code: AdminConstants.USERS.ALL,
      };
      setPermittedStateOptionsList([allStatesOption, ...allStatesList]);
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingStates = false;
        }),
      );
    }
  };

  const updateUserSettings = async (payload: UpdateUserProfilePayloadDto) => {
    setApiLoadersState(
      produce(draft => {
        draft.isUpdatingUserProfile = true;
      }),
    );

    try {
      const updateUserProfileApiResponse = await updateUserProfile(
        userId,
        payload,
      );
      enqueueSnackbar(
        updateUserProfileApiResponse.data.message,
        SnackBarConfig.getSuccess(),
      );
      // init();
      navigate(PrivateNavigationRoutes.Admins.ChildRoutes.Users.FullPath, {
        state: {
          updatedUserSettings: true,
          userActivityStatus: userProfileByID?.status ?? '',
        },
      });
    } catch (errorResponse: any) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isUpdatingUserProfile = false;
        }),
      );
    }
  };

  // API section ends

  // handle section starts

  const handleCloseUpdateTitleAndRoleDialog = () => {
    setShowTitleAndRoleEditDialog(false);
  };

  const handleMarkAsActive = () => {
    setShowMarkAsActiveConfirmationDialog(true);
  };

  const handleDeleteStateCodeChip = (stateCode: IStateCode) => () => {
    const newSelectedStateCodeList =
      editUserSettingsFormState.selectedStatesCodeList.filter(
        selectedState => selectedState.id !== stateCode.id,
      );
    setEditUserSettingsFormState(
      produce(draft => {
        draft.selectedStatesCodeList = newSelectedStateCodeList;
      }),
    );
    const newSelectedStateIds = editUserSettingsFormState.states.filter(
      stateId => stateId !== stateCode.id,
    );
    setEditUserSettingsFormState(
      produce(draft => {
        draft.states = newSelectedStateIds;
      }),
    );
    if (newSelectedStateCodeList.length > 0) {
      setIsSaveEditedUserSettingsButtonDisabled(false);
    } else {
      setIsSaveEditedUserSettingsButtonDisabled(true);
    }
  };

  const handleOnSelectionChangeOfCategoryAssignmentLevelsList = (
    value: string,
    taskCategory: GetAllTaskCategoriesDataRow,
    isInitialChange = false,
  ) => {
    const newCategories = editUserSettingsFormState.categories?.map(
      category => {
        if (category.taskCategoryId === taskCategory.id) {
          return {
            taskCategoryId: category.taskCategoryId,
            assignmentLevelId: value,
          };
        }
        return category;
      },
    );

    setEditUserSettingsFormState(
      produce(draft => {
        draft.categories = newCategories;
      }),
    );
    if (isInitialChange) setIsSaveEditedUserSettingsButtonDisabled(true);
    else setIsSaveEditedUserSettingsButtonDisabled(false);
  };

  const handleOnChangeQueueAssignmentOptionCheckbox = (
    taskCategory: GetAllTaskCategoriesDataRow,
    event?: ChangeEvent<HTMLInputElement>,
    isInitialChange = false,
  ) => {
    if (event && event.target.checked) {
      setEditUserSettingsFormState(
        produce(draft => {
          draft.categories = [
            ...draft.categories,
            {
              taskCategoryId: taskCategory.id,
              assignmentLevelId:
                allAssignmentsLevelsList.find(
                  assignmentLevel =>
                    assignmentLevel.nameId === AdminConstants.USERS.PRIMARY,
                )?.id ?? '',
            },
          ];
        }),
      );
    } else {
      setEditUserSettingsFormState(
        produce(draft => {
          draft.categories = draft.categories?.filter(
            category => category.taskCategoryId !== taskCategory.id,
          );
        }),
      );
      setUserProfileByID(
        produce(draft => {
          draft.taskCategories = draft.taskCategories?.filter(
            category => category.taskCategoryId !== taskCategory.id,
          );
        }),
      );
    }
    if (isInitialChange) setIsSaveEditedUserSettingsButtonDisabled(true);
    else setIsSaveEditedUserSettingsButtonDisabled(false);
  };

  const handleSearchStates = useDebouncedCallback((searchText = '') => {
    fetchStatesList(searchText);
  }, SEARCH_DEBOUNCE_DELAY_IN_MS);

  const handleSearchStateInputChange = async (newStateInputValue: string) => {
    setEditUserSettingsFormState(
      produce(draft => {
        draft.searchStateInput = newStateInputValue;
      }),
    );
    handleSearchStates(newStateInputValue);
  };

  const handleSelectStateChange = (
    selectedStateOption: GetAllStatesDataRow,
    isInitialChange = false,
  ) => {
    // if(selectedStateOption === ''){
    //   setEditUserSettingsFormState(
    //     produce(draft => {
    //       draft.selectedStatesCodeList = [];
    //     }),
    //   );
    // }
    // implemented fn which would remove all from list if available and selectedStateId is all along with the state codes
    if (selectedStateOption.nameId === AdminConstants.USERS.ALL.toLowerCase()) {
      setEditUserSettingsFormState(
        produce(draft => {
          draft.states = [];
          draft.isAllState = true;
          draft.searchStateInput = '';
        }),
      );
      const allStateCode: IStateCode = {
        id: AdminConstants.USERS.ALL.toLowerCase(),
        stateCode: AdminConstants.USERS.ALL.toUpperCase(),
      };
      setEditUserSettingsFormState(
        produce(draft => {
          draft.selectedStatesCodeList = [allStateCode];
        }),
      );
      handleSearchStateInputChange('');
      if (isInitialChange) setIsSaveEditedUserSettingsButtonDisabled(true);
      else setIsSaveEditedUserSettingsButtonDisabled(false);
    } else {
      const isStateIdAlreadyPresent = editUserSettingsFormState.states.filter(
        editUserSettingsState =>
          editUserSettingsState === selectedStateOption.id,
      );
      if (isStateIdAlreadyPresent?.length === 0) {
        const newSelectedStatesCodeList = [
          ...editUserSettingsFormState.selectedStatesCodeList,
          { id: selectedStateOption.id, stateCode: selectedStateOption.code },
        ].filter(
          editUserSettingsState =>
            editUserSettingsState.id !== AdminConstants.USERS.ALL.toLowerCase(),
        );

        setEditUserSettingsFormState(
          produce(draft => {
            draft.selectedStatesCodeList = newSelectedStatesCodeList;
          }),
        );

        const newStateIds = [
          ...editUserSettingsFormState.states,
          selectedStateOption.id,
        ];

        setEditUserSettingsFormState(
          produce(draft => {
            draft.stateNsa = StateNsaAllStateEnum.STATE;
            draft.states = newStateIds;
            draft.isAllState = false;
            draft.searchStateInput = '';
          }),
        );
        handleSearchStateInputChange('');
        if (isInitialChange) setIsSaveEditedUserSettingsButtonDisabled(true);
        else setIsSaveEditedUserSettingsButtonDisabled(false);
      } else {
        enqueueSnackbar(
          `${t('ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.STATE')} ${
            selectedStateOption.label
          } ${t(
            'ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.IS_ALREADY_SELECTED',
          )}`,
          SnackBarConfig.getInfo(),
        );
      }
    }
  };

  const handleStateNsaOptionChange = async (
    selectedOptionValue: string,
    isInitialChange = false,
  ) => {
    setEditUserSettingsFormState(
      produce(draft => {
        draft.stateNsa = selectedOptionValue;
      }),
    );
    if (selectedOptionValue.toLowerCase() === StateNsaAllStateEnum.NSA) {
      setEditUserSettingsFormState(
        produce(draft => {
          draft.selectedStatesCodeList = [];
          draft.states = [];
          draft.isAllState = false;
        }),
      );
    }
    if (selectedOptionValue === '') {
      setEditUserSettingsFormState(
        produce(draft => {
          draft.selectedStatesCodeList = [];
          draft.states = [];
          draft.isAllState = false;
        }),
      );
    }
    if (isInitialChange || selectedOptionValue === StateNsaAllStateEnum.STATE)
      setIsSaveEditedUserSettingsButtonDisabled(true);
    else setIsSaveEditedUserSettingsButtonDisabled(false);
  };

  // handle section ends

  // pre-populate section starts

  const prePopulateQueueAssignment = () => {
    if (userProfileByID?.taskCategories?.length) {
      const newTaskCategories: ICategoryOption[] = [];
      userProfileByID?.taskCategories?.forEach(userTaskCategory => {
        const taskCategoryFromAllTaskCategoryList = allTaskCategoriesList.find(
          taskCategoryFromAllTaskList =>
            taskCategoryFromAllTaskList.id === userTaskCategory.taskCategoryId,
        );
        if (taskCategoryFromAllTaskCategoryList) {
          newTaskCategories.push(userTaskCategory);
        }
      });

      setEditUserSettingsFormState(
        produce(draft => {
          draft.categories = newTaskCategories;
        }),
      );
    }
  };

  const prePopulateStateAndSelectedStateByUser = () => {
    const isPreSelectedStatePresentInEditUserSettingsFormState =
      userProfileByID?.states?.some(userProfileState =>
        editUserSettingsFormState?.states?.includes(userProfileState?.stateId),
      );

    if (
      !isPreSelectedStatePresentInEditUserSettingsFormState &&
      !editUserSettingsFormState.isAllState
    ) {
      if (
        userProfileByID.stateNsa.toLowerCase() ===
        StateNsaAllStateEnum.ALL_STATE.toLowerCase()
      ) {
        const allStatesOption: GetAllStatesDataRow = {
          id: StateNsaAllStateEnum.ALL_STATE,
          label: AdminConstants.USERS.ALL_CAPITALIZED,
          nameId: AdminConstants.USERS.ALL,
          code: AdminConstants.USERS.ALL,
        };
        handleSelectStateChange(allStatesOption, true);
      } else if (
        userProfileByID?.stateNsa?.toLowerCase() ===
          StateNsaAllStateEnum.STATE &&
        userProfileByID?.states?.length &&
        permittedStateOptionsList.length > 0
      ) {
        const userStateCodeList: IStateCode[] = [];
        const userStateIds: string[] = [];
        userProfileByID?.states?.forEach(userState => {
          const stateDetails = permittedStateOptionsList.find(
            permittedState => permittedState?.id === userState.stateId,
          );

          if (stateDetails) {
            userStateCodeList.push({
              id: stateDetails.id,
              stateCode: stateDetails.code,
            });
            setEditUserSettingsFormState(
              produce(draft => {
                draft.selectedStatesCodeList = userStateCodeList;
              }),
            );
            userStateIds.push(stateDetails.id);
            setEditUserSettingsFormState(
              produce(draft => {
                draft.states = userStateIds;
              }),
            );
          }
        });
      }
    }
  };

  const prePopulateTitleAndRoles = () => {
    setEditUserSettingsFormState(
      produce(draft => {
        draft.title = userProfileByID.title;
        draft.roles = userProfileByID.roles;
      }),
    );
  };

  const prePopulateData = async () => {
    const initialStateNSA = userProfileByID?.stateNsa
      ? userProfileByID.stateNsa.toLowerCase() === StateNsaAllStateEnum.STATE ||
        userProfileByID.stateNsa.toLowerCase() ===
          StateNsaAllStateEnum.ALL_STATE.toLowerCase()
        ? StateNsaAllStateEnum.STATE
        : userProfileByID.stateNsa.toLowerCase() === StateNsaAllStateEnum.NSA
        ? StateNsaAllStateEnum.NSA
        : ''
      : '';

    prePopulateTitleAndRoles();
    await handleStateNsaOptionChange(initialStateNSA, true);
    prePopulateStateAndSelectedStateByUser();
    prePopulateQueueAssignment();
    setIsPrePopulatingStateOptionsFinished(true);
  };

  useEffect(() => {
    const prePopulateDataAfterFetchingUserProfileIsFinished = async () => {
      await prePopulateData();
      setApiLoadersState(
        produce(draft => {
          draft.isInitialFetchInProgress = false;
        }),
      );
    };

    if (
      (userProfileByID?.stateNsa?.length ||
        (permittedStateOptionsList.length &&
          allTaskCategoriesList?.length > 0)) ??
      !isPrePopulatingStateOptionsFinished
    ) {
      prePopulateDataAfterFetchingUserProfileIsFinished();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userProfileByID?.stateNsa,
    userProfileByID?.states,
    permittedStateOptionsList.length,
    isPrePopulatingStateOptionsFinished,
    allTaskCategoriesList,
  ]);

  // pre-populate section ends

  const init = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isInitialFetchInProgress = true;
      }),
    );

    try {
      await Promise.all([
        fetchAllTaskCategories(),
        fetchAllAssignmentLevels(),
        fetchStatesList(),
        fetchUserProfileById(),
      ]);
    } catch (errorResponse: any) {
      const errorMessage = t('ADMIN.INIT_ERROR_MESSAGE');
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      userProfileByID.profilePicId &&
      !profilePicsUrlsWithLoaderFlagById[userId]
    ) {
      getProfilePictureById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileByID.profilePicId]);
  // validation section starts

  const validateCategories = (): boolean => {
    const isRoleAdminOrManager = editUserSettingsFormState.roles.some(
      role =>
        role?.roleLabel?.toLowerCase() ===
          AdminConstants.USERS.USER_SETTINGS.ROLE_ADMIN.toLowerCase() ||
        role?.roleLabel?.toLowerCase() ===
          AdminConstants.USERS.USER_SETTINGS.MANAGER.toLowerCase(),
    );
    if (
      editUserSettingsFormState.categories?.length < 1 &&
      !isRoleAdminOrManager
    ) {
      enqueueSnackbar(
        t(
          'ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_AT_LEAST_1_QUEUE_ASSIGNMENT',
        ),
        SnackBarConfig.getError(),
      );
      return false;
    }
    return true;
  };

  const validateEditUserSettings = () => {
    const hasEmailBasedCategory =
      editUserSettingsFormState.categories.length > 0 &&
      editUserSettingsFormState.categories.some(selectedCategory =>
        allTaskCategoriesList.some(
          category =>
            category.id === selectedCategory.taskCategoryId &&
            category.isEmailBased,
        ),
      );
    if (hasEmailBasedCategory) {
      if (
        !editUserSettingsFormState.stateNsa ||
        (editUserSettingsFormState.stateNsa === StateNsaAllStateEnum.STATE &&
          !(
            editUserSettingsFormState.isAllState ||
            editUserSettingsFormState.selectedStatesCodeList.length > 0
          ))
      ) {
        enqueueSnackbar(
          t(
            'ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_VALUE_FOR_STATE_NSA',
          ),
          SnackBarConfig.getError(),
        );
        return false;
      }
    }
    if (
      editUserSettingsFormState.stateNsa.toLowerCase() ===
        StateNsaAllStateEnum.NSA ||
      editUserSettingsFormState.stateNsa === ''
    ) {
      return validateCategories();
    }

    if (
      editUserSettingsFormState.stateNsa.toLowerCase() ===
        StateNsaAllStateEnum.STATE ||
      editUserSettingsFormState.stateNsa === ''
    ) {
      if (
        editUserSettingsFormState.isAllState ||
        editUserSettingsFormState.selectedStatesCodeList.length > 0
      ) {
        return validateCategories();
      } else {
        enqueueSnackbar(
          t(
            'ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_VALID_STATE_OPTIONS',
          ),
          SnackBarConfig.getError(),
        );
        return false;
      }
    }
    return false;
  };

  // validation section ends

  // create payload section starts

  const createTaskCategoriesPayload = () => {
    const newTaskCategories: ICategoryOption[] = [];

    if (editUserSettingsFormState?.categories?.length) {
      editUserSettingsFormState?.categories?.forEach(category => {
        const isExistingTaskCategory = userProfileByID?.taskCategories?.some(
          taskCategory =>
            taskCategory?.taskCategoryId === category?.taskCategoryId,
        );
        if (isExistingTaskCategory) {
          const existingUserTaskCategory: ICategoryOption = {
            taskCategoryId: category.taskCategoryId,
            assignmentLevelId: category.assignmentLevelId,
          };
          newTaskCategories.push(existingUserTaskCategory);
        } else {
          const newSelectedTaskCategory = {
            taskCategoryId: category.taskCategoryId,
            assignmentLevelId: category.assignmentLevelId,
          };
          newTaskCategories.push(newSelectedTaskCategory);
        }
      });
    }
    return newTaskCategories;
  };

  const createRolesPayload = () => {
    const newRoles: RoleOptionPayloadType[] = [];
    if (editUserSettingsFormState?.roles?.length) {
      editUserSettingsFormState?.roles?.forEach(role => {
        const isExistingRole = userProfileByID?.roles?.some(
          userRole => userRole.roleId === role.roleId,
        );
        if (isExistingRole) {
          const existingUserRole: RoleOptionPayloadType = {
            id: userProfileByID.roles.find(
              userRole => userRole.roleId === role.roleId,
            )?.id,
            roleId: role.roleId,
          };
          newRoles.push(existingUserRole);
        } else {
          const newSelectedRole: RoleOptionPayloadType = {
            roleId: role.roleId,
          };
          newRoles.push(newSelectedRole);
        }
      });
    }
    return newRoles;
  };

  const createStatesPayload = () => {
    const newStates: StateIdType[] = [];
    if (editUserSettingsFormState?.states?.length) {
      editUserSettingsFormState?.states?.forEach(editUserSettingsState => {
        const isExistingUserState = userProfileByID?.states?.some(
          userState => userState.stateId === editUserSettingsState,
        );
        if (isExistingUserState) {
          const existingUserState: StateIdType = {
            id: userProfileByID.states.find(
              userState => userState.stateId === editUserSettingsState,
            )?.id,
            stateId: editUserSettingsState,
          };
          newStates.push(existingUserState);
        } else {
          const newSelectedState = {
            stateId: editUserSettingsState,
          };
          newStates.push(newSelectedState);
        }
      });
    }
    return newStates;
  };

  const createUpdateUserSettingsPayload = () => {
    const payload: UpdateUserProfilePayloadDto = {
      titleId: editUserSettingsFormState?.title?.id,
      roles: createRolesPayload(),
      stateNsa: editUserSettingsFormState.isAllState
        ? StateNsaAllStateEnum.ALL_STATE
        : editUserSettingsFormState.stateNsa,
      taskCategories: createTaskCategoriesPayload(),
      states: createStatesPayload(),
    };
    return payload;
  };

  // create payload section ends

  // update user settings
  const saveEditedUserSettings = () => {
    const validateForm = validateEditUserSettings();
    if (validateForm) {
      const payload = createUpdateUserSettingsPayload();
      updateUserSettings(payload);
    }
  };

  // Top Bar section starts

  const renderMarkAsActiveConfirmationDialog = () => (
    <MarkAsActiveOrInactiveUserConfirmationDialog
      userId={userProfileByID.id}
      isUserActive={false}
      isDialogOpen={showMarkAsActiveConfirmationDialog}
      onClose={() => {
        setShowMarkAsActiveConfirmationDialog(false);
        init();
      }}
      onSubmitCloseDialog={() => {
        setShowMarkAsActiveConfirmationDialog(false);
        init();
      }}
    />
  );

  const renderInactiveUserTopBarButtons = () => (
    <ButtonsContainer>
      {!apiLoadersState.isUpdatingUserProfile ? (
        <SaveButtonStyled
          onClick={handleMarkAsActive}
          disabled={apiLoadersState.isMarkingAsActive}
        >
          <span>{t('ADMIN.USERS_TAB.MARK_ACTIVE')}</span>
        </SaveButtonStyled>
      ) : (
        <SaveButtonDisabledStyled disabled={apiLoadersState.isMarkingAsActive}>
          {apiLoadersState.isMarkingAsActive
            ? t('ADMIN.USERS_TAB.MARKING_ACTIVE')
            : t('ADMIN.USERS_TAB.MARK_ACTIVE')}
        </SaveButtonDisabledStyled>
      )}
    </ButtonsContainer>
  );

  const renderActiveUserTopBarButtons = () => (
    <ButtonsContainer>
      <CancelButtonStyled
        onClick={() => navigate(-1)}
        disabled={apiLoadersState.isUpdatingUserProfile}
      >
        {t('ADMIN.USERS_TAB.USER_SETTINGS.CANCEL')}
      </CancelButtonStyled>
      {!apiLoadersState.isUpdatingUserProfile ? (
        <SaveButtonStyled
          onClick={saveEditedUserSettings}
          disabled={
            apiLoadersState.isUpdatingUserProfile ||
            isSaveEditedUserSettingsButtonDisabled
          }
        >
          <span>{t('ADMIN.USERS_TAB.USER_SETTINGS.SAVE')}</span>
        </SaveButtonStyled>
      ) : (
        <SaveButtonDisabledStyled
          disabled={apiLoadersState.isUpdatingUserProfile}
        >
          {apiLoadersState.isUpdatingUserProfile
            ? t('ADMIN.USERS_TAB.USER_SETTINGS.SAVING')
            : t('ADMIN.USERS_TAB.USER_SETTINGS.SAVE')}
        </SaveButtonDisabledStyled>
      )}
    </ButtonsContainer>
  );

  const renderTopBar = () => (
    <UserSettingsTopBar>
      <TopBarWrapperGrid height="5rem">
        <TopBarButtonsContainer>
          <BackButton
            isLoading={apiLoadersState.isUpdatingUserProfile}
            onClick={() =>
              apiLoadersState.isUpdatingUserProfile ? null : navigate(-1)
            }
          >
            {t('ADMIN.USERS_TAB.USER_SETTINGS.BACK')}
          </BackButton>
          {apiLoadersState.isInitialFetchInProgress ? (
            <SubLoaderContainerForButtons>
              <Loader size={30} />
            </SubLoaderContainerForButtons>
          ) : userProfileByID?.status?.toLowerCase() ===
            AdminConstants.USERS.ACTIVE ? (
            renderActiveUserTopBarButtons()
          ) : (
            renderInactiveUserTopBarButtons()
          )}
        </TopBarButtonsContainer>
      </TopBarWrapperGrid>
    </UserSettingsTopBar>
  );

  // Top Bar section ends

  // profile section starts

  const renderProfileSection = () => {
    return (
      <ProfileContainer>
        {apiLoadersState.isInitialFetchInProgress ? (
          <>
            <ProfileTitleForLoader>
              {t('ADMIN.USERS_TAB.USER_SETTINGS.PROFILE')}
            </ProfileTitleForLoader>
            <SubLoaderContainer>
              <Loader size={30} />
            </SubLoaderContainer>
          </>
        ) : (
          <>
            <AvatarContainer>
              {profilePicsUrlsWithLoaderFlagById[userId]
                ?.isProfilePicLoading ? (
                <LoadingGridForProfilePic>
                  <Loader size={40} />
                </LoadingGridForProfilePic>
              ) : (
                <Avatar
                  src={
                    profilePicsUrlsWithLoaderFlagById[userId]?.profilePicUrl ??
                    ''
                  }
                  sx={{ width: 104, height: 104 }}
                />
              )}
            </AvatarContainer>
            <ProfileInfoContainer>
              <ProfileTitle>
                {t('ADMIN.USERS_TAB.USER_SETTINGS.PROFILE')}
              </ProfileTitle>
              <InfoContainer>
                {/* Name */}
                <InfoSubContainer>
                  <InfoTitle>
                    {t('ADMIN.USERS_TAB.USER_SETTINGS.NAME')}
                  </InfoTitle>
                  <InfoValue>{userProfileByID?.name ?? ''}</InfoValue>
                </InfoSubContainer>
                {/* Email */}
                <InfoSubContainer>
                  <InfoTitle>
                    {t('ADMIN.USERS_TAB.USER_SETTINGS.EMAIL')}
                  </InfoTitle>
                  <Tooltip title={userProfileByID?.email}>
                    <InfoValue>
                      <Email>{userProfileByID?.email}</Email>
                    </InfoValue>
                  </Tooltip>
                </InfoSubContainer>
                {/* Phone Number */}
                <InfoSubContainer>
                  <InfoTitle>
                    {t('ADMIN.USERS_TAB.USER_SETTINGS.PHONE_NUMBER')}
                  </InfoTitle>
                  <InfoValue>{userProfileByID?.phone ?? '-'}</InfoValue>
                </InfoSubContainer>
                <InfoSubContainer style={{ position: 'relative' }}>
                  <DividerStyled />
                  <EditImg
                    src={EditIcon}
                    alt="close"
                    onClick={() => setShowTitleAndRoleEditDialog(true)}
                  />
                </InfoSubContainer>
                <TitleAndRoleContainer>
                  {/* Title */}
                  <InfoSubContainer>
                    <InfoTitle>
                      {t('ADMIN.USERS_TAB.USER_SETTINGS.TITLE')}
                    </InfoTitle>
                    <InfoValue>
                      {updatedTitleAndRole.title.label?.length
                        ? updatedTitleAndRole.title.label
                        : userProfileByID?.title.label}
                    </InfoValue>
                  </InfoSubContainer>
                  {/* Role */}
                  <InfoSubContainer>
                    <InfoTitle>
                      {t('ADMIN.USERS_TAB.USER_SETTINGS.ROLE')}
                    </InfoTitle>
                    <InfoValue>
                      {convertRoleLabelsToCommaSeparatedStringFromRoleIds(
                        updatedTitleAndRole.roles?.length
                          ? updatedTitleAndRole.roles
                          : userProfileByID?.roles,
                      )}
                    </InfoValue>
                  </InfoSubContainer>
                </TitleAndRoleContainer>
              </InfoContainer>
            </ProfileInfoContainer>
          </>
        )}
      </ProfileContainer>
    );
  };

  // profile section ends

  // stateNsa section starts

  const renderStateCodeChips = () => {
    return apiLoadersState.isInitialFetchInProgress ? null : (
      <StateCodeChipsContainer>
        {editUserSettingsFormState.selectedStatesCodeList?.map(
          selectedState => {
            return (
              <StyledCustomChip
                key={selectedState.id}
                label={selectedState.stateCode}
                onDelete={handleDeleteStateCodeChip(selectedState)}
                variant={undefined}
                deleteIcon={<img src={CrossIcon} alt="close" />}
              />
            );
          },
        )}
      </StateCodeChipsContainer>
    );
  };

  const renderSelectStateList = () => {
    return (
      <SelectStateListContainer>
        <Controller
          name="selectPermittedStates"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <>
              <LabelStyled>
                {t(
                  'ADMIN.USERS_TAB.ADD_USER_FORM.SELECT_PERMITTED_STATES_REQUIRED',
                )}
              </LabelStyled>
              <SelectSearchInput
                sx={{
                  // Remove padding for the input root when Autocomplete hasPopupIcon
                  '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot': {
                    paddingRight: 0,
                  },
                  // Remove padding for the outlined input root when Autocomplete hasPopupIcon or hasClearIcon
                  '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root, &.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
                    {
                      paddingRight: 1.5,
                    },
                  '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
                    padding: 0.7,
                  },
                }}
                loading={
                  apiLoadersState.isFetchingStates &&
                  !apiLoadersState.isInitialFetchInProgress
                }
                setIsOptionDropdownOpen={setIsOptionDropdownOpen}
                clearOnBlur={false}
                value={null}
                onChange={(e, newValue) =>
                  handleSelectStateChange(newValue as GetAllStatesDataRow)
                }
                inputValue={editUserSettingsFormState.searchStateInput}
                onInputChange={(event, value) => {
                  handleSearchStateInputChange(value);
                }}
                options={permittedStateOptionsList}
                getOptionLabel={(option: string | GetAllStatesDataRow) =>
                  typeof option === 'string' ? option : option.label
                }
                includeInputInList
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder={t(
                      'ADMIN.USERS_TAB.ADD_USER_FORM.SEARCH_STATE_AND_SELECT',
                    )}
                    style={{ width: '30.5rem', height: '3rem' }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: null,
                      endAdornment: (
                        <CustomImg
                          src={ArrowSolidIcon}
                          alt="toggle state dropdown"
                          style={{
                            transform: isOptionDropdownOpen
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                            transition: 'transform 0.3s ease',
                            cursor: 'pointer',
                          }}
                        />
                      ),
                    }}
                  />
                )}
              />
            </>
          )}
        />
      </SelectStateListContainer>
    );
  };

  const renderStateNsaDropdowns = () => {
    const hasEmailBasedCategory =
      editUserSettingsFormState.categories.length > 0 &&
      editUserSettingsFormState.categories.some(selectedCategory =>
        allTaskCategoriesList.some(
          category =>
            category.id === selectedCategory.taskCategoryId &&
            category.isEmailBased,
        ),
      );

    return (
      <StateNsaContainer>
        <StateNsaTitle>
          {t('ADMIN.USERS_TAB.ADD_USER_FORM.STATE_NSA')}
        </StateNsaTitle>
        {apiLoadersState.isInitialFetchInProgress ? (
          <SubLoaderContainer>
            <Loader size={30} />
          </SubLoaderContainer>
        ) : (
          <SelectStateNsaDropdownContainer>
            <SelectStateNsaDropdownTitle>
              {hasEmailBasedCategory
                ? t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT_REQUIRED')
                : t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT')}
            </SelectStateNsaDropdownTitle>
            <SelectStateNsaDropdownWrapper>
              <SelectTextInput
                options={selectStateNsaDropdownOption}
                disabled={userProfileByID?.id?.length < 2}
                showPlaceHolder={true}
                onChange={e =>
                  handleStateNsaOptionChange(e.target.value as string)
                }
                variant={'filled'}
                placeholder={t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT')}
                label={t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT_REQUIRED')}
                selectValue={editUserSettingsFormState.stateNsa}
                width="30.5rem"
                enableSelect={false}
              />
            </SelectStateNsaDropdownWrapper>
            {editUserSettingsFormState.stateNsa === StateNsaAllStateEnum.STATE
              ? renderSelectStateList()
              : null}
          </SelectStateNsaDropdownContainer>
        )}
      </StateNsaContainer>
    );
  };

  // stateNsa section ends

  // Queue Assignments levels section starts

  const renderQueueAssignmentOption = (
    taskCategory: GetAllTaskCategoriesDataRow,
    userTaskCategory?: ICategoryOption,
  ) => {
    const isSelected = editUserSettingsFormState?.categories?.some(
      category => category?.taskCategoryId === taskCategory?.id,
    );
    let defaultTabValue: string | undefined = '';
    if (userTaskCategory) {
      const assignmentLevelLabel = allAssignmentsLevelsList.find(
        assignmentLevel =>
          assignmentLevel.id === userTaskCategory.assignmentLevelId,
      )?.id;
      defaultTabValue = assignmentLevelLabel;
    }

    return (
      <QueueAssignmentOptionContainer key={taskCategory.id}>
        <div>
          <FormControlLabel
            control={
              <CheckboxStyled
                disabled={userProfileByID?.id?.length < 2}
                onChange={event =>
                  handleOnChangeQueueAssignmentOptionCheckbox(
                    taskCategory,
                    event,
                  )
                }
                color="secondary"
                checked={isSelected} // Check if the category is selected
              />
            }
            label={taskCategory.label}
            value={taskCategory.id}
          />
        </div>
        <TabFilterContainer>
          {isSelected && ( // Render TabFilter only if the category is selected
            <TabFilter
              filterOptions={mapDropdownOptionToDropdownOptionsType(
                allAssignmentsLevelsList,
              )}
              onSelectionChange={(value: string) => {
                handleOnSelectionChangeOfCategoryAssignmentLevelsList(
                  value,
                  taskCategory,
                );
              }}
              defaultSelectedTab={defaultTabValue}
            />
          )}
        </TabFilterContainer>
      </QueueAssignmentOptionContainer>
    );
  };

  const renderQueueAssignments = () => {
    return (
      <QueueAssignmentsContainer>
        <QueueAssignmentsTitle>
          {t('ADMIN.USERS_TAB.ADD_USER_FORM.QUEUE_ASSIGNMENTS')}
        </QueueAssignmentsTitle>
        {apiLoadersState.isInitialFetchInProgress ? (
          <SubLoaderContainer>
            <Loader size={30} />
          </SubLoaderContainer>
        ) : (
          <QueueAssignmentsOptionsContainer>
            <FormGroup>
              {/* map over list of queue assignments and use the renderQueueAssignmentOption */}
              {allTaskCategoriesList?.map(taskCategory => {
                const userTaskCategory = userProfileByID?.taskCategories?.find(
                  userTaskCategoryForRendering =>
                    userTaskCategoryForRendering.taskCategoryId ===
                    taskCategory.id,
                );
                return renderQueueAssignmentOption(
                  taskCategory,
                  userTaskCategory,
                );
              })}
            </FormGroup>
          </QueueAssignmentsOptionsContainer>
        )}
      </QueueAssignmentsContainer>
    );
  };

  // Queue Assignments levels section ends

  const renderUserSettingsBody = () => {
    return (
      <BodyCardsContainer>
        <LeftSectionContainer>
          <ProfileCardContainer>{renderProfileSection()}</ProfileCardContainer>
          <StateNsaContainer>
            {renderStateNsaDropdowns()}
            {editUserSettingsFormState.selectedStatesCodeList?.length
              ? renderStateCodeChips()
              : null}
          </StateNsaContainer>
        </LeftSectionContainer>
        <RightSectionContainer>
          <QueueAssignmentsContainer>
            {renderQueueAssignments()}
          </QueueAssignmentsContainer>
          <SetTimeOffContainer>
            <TimeOff
              subText={t('PRIVATE.PROFILE.SET_TIME_OFF_CAPTION')}
              userId={userId}
              timeOffIds={userProfileByID.timeOffs}
              addNewTimeOffApi={addNewTimeOffForUser}
              updateTimeOffApi={updateTimeOffForUser}
              deleteTimeOffApi={deleteTimeOffForUser}
            />
          </SetTimeOffContainer>
        </RightSectionContainer>
      </BodyCardsContainer>
    );
  };

  const onSubmitUpdateTitleAndRoleForm = (
    updateTitleAndRoleFormState: IUpdatedTitleAndRole,
  ) => {
    setShowTitleAndRoleEditDialog(false);

    setEditUserSettingsFormState(
      produce(draft => {
        draft.title = updateTitleAndRoleFormState.title;
        draft.roles = updateTitleAndRoleFormState.roles;
      }),
    );

    setUpdatedTitleAndRole(
      produce(draft => {
        draft.title = updateTitleAndRoleFormState.title;
        draft.roles = updateTitleAndRoleFormState.roles;
      }),
    );

    setIsSaveEditedUserSettingsButtonDisabled(false);
  };

  return (
    <UserSettingsContainer>
      {apiLoadersState.isUpdatingUserProfile ||
      apiLoadersState.isInitialFetchInProgress ? (
        <LoaderContainer>
          <Loader size={60} />
        </LoaderContainer>
      ) : null}
      {renderTopBar()}
      {renderUserSettingsBody()}
      {showTitleAndRoleEditDialog ? (
        <UpdateTitleAndRoleDialog
          handleClose={handleCloseUpdateTitleAndRoleDialog}
          isDialogOpen={showTitleAndRoleEditDialog}
          onSubmitTitleAndRoleSelection={onSubmitUpdateTitleAndRoleForm}
          currentSelectedRoles={editUserSettingsFormState.roles}
          currentSelectedTitle={editUserSettingsFormState.title}
        />
      ) : null}
      {showMarkAsActiveConfirmationDialog &&
        renderMarkAsActiveConfirmationDialog()}
    </UserSettingsContainer>
  );
};

export default UsersSettings;
