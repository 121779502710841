import { ReactElement, useEffect, useRef, useState } from 'react';
import QueueHistory from '../../admin/queue/queue-history/QueueHistory';
import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { APP } from 'src/styles/variables';
import {
  CircularProgressStyled,
  UserSearchBarStyledDiv,
  ProgressBarGridStyled,
  ProgressBarContainer,
  ProgressTextStyled,
  ParentGridContainerStyled,
} from '../user-queue/UserQueueStyle';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { getErrorMessage } from '@utils/ErrorUtils';
import { enqueueSnackbar } from 'notistack';
import CustomProgressBar from '@components/custom-progress-bar/CustomProgressBar';
import { getTaskProgress } from 'src/services/service-handlers/private/tasks-handlers/TasksPrivateService';
import { GetProgressDataDto } from 'src/services/service-handlers/private/tasks-handlers/TasksServiceMapper';
import { produce } from 'immer';
import { FilterConfigurationType } from 'src/types/adminTypes';
import { FilterIconButton } from './UserHistoryStyle';
import Filter from '@assets/images/Filter.svg';
import SearchAndFilterDialog, {
  ISearchAndFilterDialogProps,
} from '@components/dialog/search-and-filter-dialog/SearchAndFilterDialog';
import USER_PORTAL_SEARCH_AND_FILTER_CONFIGURATIONS from '../SearchAndFilterConfigurations';
import {
  setIsRefetchingNeeded,
  setQueueSearchAndFilterOptions,
  setIsFilterResetButtonClicked,
} from '@modules/home/state/queue/QueueSlice';
import { useDispatch } from 'react-redux';
import { executeAfterTimeout } from '@modules/home/utils/Utils';
import moment from 'moment';
import axios, { CancelTokenSource } from 'axios';
import Loader from '@components/loaders/Loader';

interface IUserHistoryState {
  progressData: GetProgressDataDto;
  isLoading: boolean;
  shouldDialogOpen: boolean;
  filterConfiguration: FilterConfigurationType;
  searchAndFilterOptions: { [key: string]: string | boolean };
}

const UserHistory = (): ReactElement => {
  const [userHistoryState, setUserHistoryState] = useState<IUserHistoryState>({
    isLoading: false,
    progressData: {
      dailyCompletedTasksCount: 0,
      dailyTasksUserTarget: 0,
    },
    shouldDialogOpen: false,
    filterConfiguration: {
      SEARCH_BY_OPTIONS: [],
      FILTER_OPTIONS: [],
    },
    searchAndFilterOptions: {},
  });
  const dispatch = useDispatch();
  const {
    progressData,
    isLoading,
    shouldDialogOpen,
    filterConfiguration,
    searchAndFilterOptions,
  } = userHistoryState;

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const fetchProgressBarCount = async () => {
    cancelTokenSource.current = axios.CancelToken.source();

    setUserHistoryState(
      produce(draft => {
        draft.isLoading = true;
      }),
    );
    try {
      const todayDate = moment().toString();
      const progressRes = await getTaskProgress(
        { todayDate },
        cancelTokenSource.current,
      );
      setUserHistoryState(
        produce(draft => {
          draft.progressData = progressRes.data;
          draft.isLoading = false;
        }),
      );
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      setUserHistoryState(
        produce(draft => {
          draft.isLoading = false;
        }),
      );
    } finally {
      cancelTokenSource.current = null;
    }
  };

  const init = async () => {
    await fetchProgressBarCount();
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dialogClose = () => {
    setUserHistoryState(
      produce(draft => {
        draft.shouldDialogOpen = false;
      }),
    );
  };

  const handleFilterIconButtonClick = () => {
    setUserHistoryState(
      produce(draft => {
        draft.shouldDialogOpen = true;
        draft.filterConfiguration =
          USER_PORTAL_SEARCH_AND_FILTER_CONFIGURATIONS.HISTORY_SEARCH_AND_FILTER_CONFIG;
      }),
    );
  };

  const handleApplySearchAndFilterButtonClick = (data: {
    [key: string]: string | boolean;
  }) => {
    if (!data?.searchValue) {
      delete data?.searchKey;
      delete data?.searchValue;
    }

    const values = Object.values(data);
    for (let i = 0; i < values.length; i++) {
      const valueItem = values[i];
      if (valueItem) {
        setUserHistoryState(
          produce(draft => {
            draft.searchAndFilterOptions = data;
          }),
        );
        dispatch(setQueueSearchAndFilterOptions({ data: data }));
        dispatch(setIsRefetchingNeeded({ data: true }));
        break;
      }
    }

    executeAfterTimeout(() => {
      setUserHistoryState(
        produce(draft => {
          draft.shouldDialogOpen = false;
        }),
      );
    }, 100);
  };

  const handleSearchAndFilterClearButtonClick = () => {
    dispatch(setQueueSearchAndFilterOptions({ data: {} }));
    setUserHistoryState(
      produce(draft => {
        draft.searchAndFilterOptions = {};
      }),
    );
    if (Object.entries(searchAndFilterOptions).length !== 0) {
      dispatch(setIsRefetchingNeeded({ data: true }));
      dispatch(setIsFilterResetButtonClicked({ isResetButtonClicked: true }));
    }

    executeAfterTimeout(() => {
      setUserHistoryState(
        produce(draft => {
          draft.shouldDialogOpen = false;
        }),
      );
    }, 100);
  };

  const renderSearchAndFilterDropdownDialog = (
    dialogProps: ISearchAndFilterDialogProps,
  ) => {
    return <SearchAndFilterDialog {...dialogProps} />;
  };

  return (
    <>
      <TopBarWrapperGrid height="5rem">
        <ParentGridContainerStyled container>
          <Grid item>
            <UserSearchBarStyledDiv>
              <FilterIconButton onClick={handleFilterIconButtonClick}>
                <img src={Filter} height={36} width={36} alt="..." />
              </FilterIconButton>
              {renderSearchAndFilterDropdownDialog({
                open: shouldDialogOpen,
                configuration: filterConfiguration,
                handleCloseBtnClick: dialogClose,
                handleApplyFilterClick: (data: {
                  [key: string]: string | boolean;
                }) => handleApplySearchAndFilterButtonClick(data),
                handleClearFilterClick: handleSearchAndFilterClearButtonClick,
                topDistance: '5.937rem',
                leftDistance: '-0.937rem',
                isLeftPosition: true,
              })}
            </UserSearchBarStyledDiv>
          </Grid>
        </ParentGridContainerStyled>
        <ProgressBarGridStyled item>
          {isLoading ? (
            <CircularProgressStyled>
              <Loader size={30} />
            </CircularProgressStyled>
          ) : (
            <ProgressBarContainer>
              <CustomProgressBar
                total={progressData?.dailyTasksUserTarget || 0}
                elements={[
                  {
                    value: progressData?.dailyCompletedTasksCount || 0,
                    fontSize: 16,
                    textColor: APP.PALETTE.COMMON.WHITE,
                    className: 'progress-segment-1',
                  },
                ]}
              />
              <ProgressTextStyled>
                {t('USER.QUEUE.PROGRESS')}
              </ProgressTextStyled>
            </ProgressBarContainer>
          )}
        </ProgressBarGridStyled>
      </TopBarWrapperGrid>
      <QueueHistory isUserPortal={true} />
    </>
  );
};

export default UserHistory;
