import { ReactElement, useState } from 'react';
import { Dialog, SxProps } from '@mui/material';
import { useSnackbar } from 'notistack';
import { produce } from 'immer';
import useTranslation from 'i18next';

import IconButton from '@components/buttons/icon-button/IconButton';
import GroupedSelectInput from '@components/select-input/GroupedSelectInput';
import SelectInput from '@components/select-input/SelectInput';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import Loader from '@components/loaders/Loader';

import {
  ActionButtonWrapperGrid,
  CancelTextButton,
  DropDownInputGrid,
  DropdownInputWrapperGrid,
  DropdownLabelTypography,
  SaveTextButton,
  SectionDividerHR,
  TopBarGrid,
  TopBarTypography,
} from './BulkEditDialogStyle';

import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { updateBulkEmailSettingsDetailURL } from 'src/services/service-handlers/private/email-handlers/EmailPrivateService';
import { APP } from 'src/styles/variables';

import CloseIcon from '@assets/images/X.svg';
import { getErrorMessage } from '@utils/ErrorUtils';

export interface IBulkEditProps {
  open: boolean;
  handleCloseBtnClick: () => void;
  handleUpdateBtnClick?: () => void;
  paperProps?: {
    elevation?: number;
    sx?: SxProps;
  };
  sx?: SxProps;
  categoryDropdownOptions: any[];
  stateOrNSADropdownOptions: any[];
  emailIds: Array<string>;
  refetchData: () => void;
}

interface IPayloadState {
  taskTypeId: string;
  stateNsa: string;
  stateId: string;
}

const BulkEditDialog = (props: IBulkEditProps): ReactElement => {
  const { t } = useTranslation;
  const { enqueueSnackbar } = useSnackbar();
  const {
    open = false,
    handleCloseBtnClick,
    sx = {
      width: '30rem',
      height: 'fit-content',
      padding: '0.937rem',
      border: '1px solid ' + APP.PALETTE.GREY.SHADE_300,
    },
    categoryDropdownOptions,
    stateOrNSADropdownOptions,
    emailIds = [],
    handleUpdateBtnClick,
    refetchData,
  } = props;

  const paperProps = {
    elevation: 0,
    sx: sx,
  };

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [payload, setPayload] = useState<IPayloadState>({
    taskTypeId: '',
    stateNsa: '',
    stateId: '',
  });

  const handleCancelButtonClick = () => {
    setPayload({
      taskTypeId: '',
      stateNsa: '',
      stateId: '',
    });
    handleCloseBtnClick();
  };

  const renderTopBar = () => {
    return (
      <TopBarGrid>
        <TopBarTypography>{t('PRIVATE.EMAILS.EDIT_EMAILS')}</TopBarTypography>
        <IconButton onClick={handleCancelButtonClick}>
          <img src={CloseIcon} height={15} width={15} alt="..." />
        </IconButton>
      </TopBarGrid>
    );
  };

  const handleDropdownChange = (
    data: string | HTMLInputElement | undefined,
  ) => {
    const isStateOrNSADropdownSelected = categoryDropdownOptions.find(
      obj => obj.optionText === data,
    )
      ? false
      : true;

    if (!isStateOrNSADropdownSelected) {
      categoryDropdownOptions.map((categoryOptionItem: any) => {
        if (categoryOptionItem.optionText === data) {
          setPayload(
            produce(draft => {
              draft.taskTypeId = categoryOptionItem.optionId;
            }),
          );
        }
      });
    } else {
      stateOrNSADropdownOptions.map((stateOrNSAOptionItem: any) => {
        if (stateOrNSAOptionItem.optionText === data) {
          if (data === AdminConstants.USERS.NSA) {
            setPayload(
              produce(draft => {
                draft.stateNsa = AdminConstants.USERS.NSA.toLowerCase();
                draft.stateId = '';
              }),
            );
          } else {
            setPayload(
              produce(draft => {
                draft.stateNsa = AdminConstants.USERS.STATE.toLowerCase();
                draft.stateId = stateOrNSAOptionItem.optionId;
              }),
            );
          }
        }
      });
    }
  };

  const handleSubmitBtnClick = async () => {
    setIsUpdating(true);

    const finalPayload: any = {
      id: emailIds,
    };

    if (payload.taskTypeId) {
      finalPayload.taskTypeId = payload.taskTypeId;
    }

    if (payload.stateNsa === AdminConstants.USERS.NSA.toLowerCase()) {
      finalPayload.stateNsa = AdminConstants.USERS.NSA.toLowerCase();
    }

    if (payload.stateNsa === AdminConstants.USERS.STATE.toLowerCase()) {
      finalPayload.stateNsa = AdminConstants.USERS.STATE.toLowerCase();
      finalPayload.stateId = payload.stateId;
    }

    try {
      const response = await updateBulkEmailSettingsDetailURL(finalPayload);
      const message = response?.data?.message ?? t('PRIVATE.EMAIL.UPDATED');
      enqueueSnackbar(message, SnackBarConfig.getSuccess());
      if (handleUpdateBtnClick) {
        handleUpdateBtnClick();
      }
      if (refetchData) {
        refetchData();
      }
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setIsUpdating(
        produce(draft => {
          draft = false;
        }),
      );
      setPayload({
        taskTypeId: '',
        stateNsa: '',
        stateId: '',
      });
    }

    setIsUpdating(false);
  };

  const renderSelectInputDropdownSections = () => {
    return (
      <DropdownInputWrapperGrid>
        <DropDownInputGrid>
          <DropdownLabelTypography>
            {t(
              'PRIVATE.EMAIL_SETTINGS.EMAIL_SETTINGS_DETAILS.CLASSIFICATION_NAME',
            )}
          </DropdownLabelTypography>
          <GroupedSelectInput
            groupedFilterOptions={categoryDropdownOptions}
            handleOptionSelect={data => handleDropdownChange(data)}
            width={'100%'}
          />
        </DropDownInputGrid>

        <DropDownInputGrid>
          <DropdownLabelTypography>
            {t('PRIVATE.EMAIL_SETTINGS.EMAIL_SETTINGS_DETAILS.STATE_OR_NSA')}
          </DropdownLabelTypography>
          <SelectInput
            dropdownOptions={stateOrNSADropdownOptions}
            isgroupingstylingneeded={'yes'}
            showSelectedOption={true}
            isDefaultValuePresent={true}
            handleChange={data => handleDropdownChange(data)}
            width={'100%'}
          />
        </DropDownInputGrid>
      </DropdownInputWrapperGrid>
    );
  };

  const renderActionButtons = () => {
    const isDisabled =
      isUpdating ||
      !(payload.taskTypeId && (payload.stateNsa || payload.stateId));

    return (
      <ActionButtonWrapperGrid>
        <CancelTextButton
          onClick={handleCancelButtonClick}
          disabled={isDisabled}
        >
          {t('ADMIN.COMMON.CANCEL')}
        </CancelTextButton>
        <SaveTextButton onClick={handleSubmitBtnClick} disabled={isDisabled}>
          {isUpdating ? <Loader size={28} /> : t('ADMIN.COMMON.SAVE')}
        </SaveTextButton>
      </ActionButtonWrapperGrid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancelButtonClick}
      PaperProps={paperProps}
      hideBackdrop={false}
    >
      {renderTopBar()}

      {renderSelectInputDropdownSections()}

      <SectionDividerHR />

      {renderActionButtons()}
    </Dialog>
  );
};

export default BulkEditDialog;
