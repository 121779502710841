import TextButton from '@components/buttons/text-button/TextButton';
import { EmailReplyTypes } from '@constants/global-constants/constants';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { APP } from 'src/styles/variables';

export const EmailReplyContainer = styled(Grid)`
  padding: 15px;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  height: fit-content;
`;

export const FromAndToAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ToStyledDiv = styled('div')`
  padding: 10px;
  background-color: ${APP.PALETTE.BACKGROUND.PAPER};
  margin-bottom: 10px;
`;
export const ToAndCcStyledDiv = styled('div')`
  padding: 10px;
  background-color: ${APP.PALETTE.BACKGROUND.PAPER};
  margin-bottom: 10px;
`;

export const FromStyledDiv = styled('div')`
  margin-bottom: 10px;
  padding: 0 10px;
  display: flex;
`;

export const EmailKeyWordSpanElement = styled('span')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const ValueSpanElement = styled('span')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  color: ${APP.PALETTE.GREY.SHADE_600};
  margin-left: 5px;
`;

export const InputStyledElement = styled('input')`
  width: 90%;
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  height: 1.5rem;
  border: none;
  padding: 5px;

  &:focus {
    outline: none;
  }
`;

export const EmailResponseTextAreaStyledDiv = styled('div')``;

export const EmailResponseTextAreaStyled = styled.textarea`
  height: 125px;
  width: 100%;
  border: none;
  padding: 10px;
  resize: none;
  margin-bottom: 5px;

  &::placeholder {
    color: ${APP.PALETTE.GREY.SHADE_600};
    font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_16PX};
    font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
  }
`;

export const SendCustomTextButton = styled(TextButton)`
  width: 4.688rem;
  border-radius: 0;
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  color: ${APP.PALETTE.COMMON.WHITE};
  padding: 6px;
  &:hover {
    color: ${APP.PALETTE.COMMON.WHITE};
    background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  }
`;

export const AttachmentCustomTextButton = styled(TextButton)`
  width: 10.188rem;
  border-radius: 0;
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  color: ${APP.PALETTE.COMMON.WHITE};
  margin-left: 1.25rem;
  padding: 6px;
  &:hover {
    color: ${APP.PALETTE.COMMON.WHITE};
    background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  }
`;

export const EmailAttachmentIconImage = styled('img')`
  padding: 0.125rem 0.25rem 0.125rem 0.125rem;
  height: 1.375rem;
  width: 1.375rem;
`;

export const DeleteImg = styled.img`
  cursor: pointer;
`;

export const EmailReplyActionButtonsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const EmailReplySendAndAttachmentButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const EmailReplyAttachmentGrid = styled(Grid)`
  width: fit-content;
  min-height: 2.18rem;
  height: 2.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  padding: 0.5rem 0 0.5rem 0.6rem;
  cursor: pointer;
  margin-bottom: 5px;
`;

export const EmailReplyAttachmentNameTypography = styled(Typography)`
  color: ${APP.PALETTE.COMMON.WHITE};
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
`;

export const EmailReplyAttachmentHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const DeleteIconForEmailAttachment = styled('img')`
  padding: 5px;
`;

type EmailRecipientsDetailsLoadingContainerType = {
  emailReplyType: string;
};

export const EmailRecipientsDetailsLoadingContainer = styled.div<EmailRecipientsDetailsLoadingContainerType>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props =>
    props.emailReplyType === EmailReplyTypes.Reply
      ? '18.6875rem'
      : '22.0625rem'};
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
`;
