import styled from '@emotion/styled';
import { Grid, IconButton } from '@mui/material';
import { APP } from 'src/styles/variables';

export const CircularProgressStyled = styled('div')``;

export const ProgressBarGridStyled = styled(Grid)`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  width: 100%;
  height: 100%;
  margin-right: 1.5rem;
`;

export const ProgressBarContainer = styled('div')`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgressTextStyled = styled('div')`
  padding-left: 0.5rem;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
`;

export const ParentGridContainerStyled = styled(Grid)`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const UserSearchBarGridStyled = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const UserSearchBarStyledDiv = styled('div')`
  margin-left: 1rem;
`;

export const FilterIconButton = styled(IconButton)`
  padding: 0.125rem;
  cursor: pointer;
`;
