import CustomChip from '@components/custom-chip/CustomChip';
import styled from '@emotion/styled';
import { Box, Button, Checkbox, Grid, Modal } from '@mui/material';
import { APP } from 'src/styles/variables';

export const UserSettingsContainer = styled.div``;

export const UserSettingsTopBar = styled.div`
  width: 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 51;
  width: 92%;
  height: 100%;
  background-color: ${APP.PALETTE.BACKGROUND.BLUE_WITH_OPACITY};
`;

export const SubLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 51;
  width: 100%;
  height: 100%;
`;

export const SubLoaderContainerForButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const TopBarButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 100%;
  margin: 0 1.2rem 0 2.1875rem;
  flex-wrap: wrap;
`;

export const BackButton = styled('div')<{ isLoading: boolean }>`
  cursor: ${({ isLoading = false }) => (isLoading ? 'not-allowed' : 'pointer')};
  text-decoration: underline;
  color: ${APP.FONT.FONT_COLOR.TERTIARY};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.625rem;
`;

// Top buttons
export const ButtonStyled = styled(Button)<{ disabled?: boolean }>`
  text-transform: capitalize;
  border-radius: 10px;
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  margin-bottom: 0.5rem;
  width: 7.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.75rem;
  margin: 0;
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${APP.PALETTE.GREY.DISABLE_GREY};
  }
`;

export const SaveButtonStyled = styled(ButtonStyled)<{ disabled?: boolean }>`
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  color: ${APP.PALETTE.COMMON.WHITE};
  &:hover {
    box-shadow: ${APP.PALETTE.GREY.BOX_SHADOW.GRAY};
    background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const SaveButtonDisabledStyled = styled(SaveButtonStyled)<{
  disabled?: boolean;
}>`
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${APP.FONT.FONT_COLOR.SECONDARY};
`;

export const CancelButtonStyled = styled(ButtonStyled)`
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  color: ${APP.PALETTE.COMMON.BLACK};
  border: 0.5px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  &:hover {
    box-shadow: ${APP.PALETTE.GREY.BOX_SHADOW.GRAY};
    background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  }
`;

// User Settings Body
export const BodyCardsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 5rem);
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
  gap: 1.25rem;
  flex-wrap: wrap;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  overflow-x: scroll;
`;

export const LeftSectionContainer = styled.div`
  width: 35.5rem;
`;

export const SubCardContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${APP.PALETTE.COMMON.WHITE};
  border-radius: 1.25rem;
  padding: 1.25rem;
`;

// Profile section
export const ProfileCardContainer = styled(SubCardContainer)`
  /* padding-top: 42px;
  padding-bottom: 30px; */
  margin-bottom: 1.25rem;
`;

export const ProfileTitleForLoader = styled(ProfileCardContainer)`
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  font-size: 1.125rem;
  top: -1.25rem;
  position: relative;
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1.25rem;
`;

export const AvatarContainer = styled.div`
  margin: 0 1.25rem 0;
`;

export const ProfileInfoContainer = styled.div`
  width: 100%;
  padding-right: 1.25rem;
  max-width: 22.625rem;
`;

export const ProfileTitle = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  margin-bottom: 13px;
`;

export const InfoContainer = styled.div``;

export const InfoSubContainer = styled.div`
  display: flex;
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  margin-bottom: 6px;
`;

export const InfoTitle = styled.div`
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  width: 8.5rem;
`;

export const InfoValue = styled.div`
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
  max-width: 14.375rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Email = styled.div`
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
  max-width: 13rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DividerStyled = styled('hr')<{ ending?: boolean }>`
  width: 100%;
  border-bottom: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;

export const EditImg = styled.img`
  position: absolute;
  top: 1.25rem;
  right: 0px;
  cursor: pointer;
`;

export const TitleAndRoleContainer = styled.div``;

export const RightSectionContainer = styled(LeftSectionContainer)``;

// State/NSA styles
export const StateNsaContainer = styled(SubCardContainer)`
  width: 100%;
  flex-direction: column;
`;

export const StateNsaTitle = styled.div`
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
`;

export const SelectStateNsaDropdownContainer = styled.div`
  margin: 0.6875rem 0 0.75rem;
`;

export const SelectStateNsaDropdownTitle = styled(StateNsaTitle)`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
`;

export const SelectStateNsaDropdownWrapper = styled.div``;

// searchable select state
export const SelectStateListContainer = styled.div`
  width: 100%;
  margin-top: 0.75rem;
`;

export const CustomImg = styled.img`
  margin-right: 0;
  padding-right: 3px;
`;

export const StateCodeChipsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  padding-left: 1.25rem;
`;

export const StyledCustomChip = styled(CustomChip)`
  color: ${APP.PALETTE.COMMON.WHITE};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  margin: 0.2rem 0.1rem 0.3rem 0.1rem;
  padding: 0.3rem;
`;

// Queue assignments section

export const QueueAssignmentsContainer = styled(SubCardContainer)`
  width: 100%;
  flex-direction: column;
  margin-bottom: 1.25rem;
`;

export const QueueAssignmentsTitle = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;

export const QueueAssignmentsOptionsContainer = styled(Box)``;

export const QueueAssignmentOptionContainer = styled.div``;

export const CheckboxStyled = styled(Checkbox)`
  & .MuiSvgIcon-root {
    font-size: 28;
  }

  color: ${APP.PALETTE.GREY.SEMI_GRAY};
`;

// tab filter section

export const TabFilterContainer = styled(Box)`
  margin-left: 1.75rem;
`;

// Set Time Off Section
export const AddButtonText = styled(Button)<{ disabled?: boolean }>`
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  color: ${APP.FONT.FONT_COLOR.PRIMARY};
  width: 5rem;
  height: 2.5rem;
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  text-transform: capitalize;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  border-radius: 0.625rem;
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const SetTimeOffContainer = styled(SubCardContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5rem;
`;

export const SetTimeoutHeading = styled.p`
  font-weight: bold;
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  color: ${APP.PALETTE.TEXT.PRIMARY};
  margin-top: 0.563rem;
`;

export const SetTimeOffCaption = styled.p`
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.H6};
  margin-top: 0.125rem;
`;

export const SetTimeOffButtonDiv = styled.div``;

export const SetTimeOffHeadingDiv = styled.div``;

export const SetTimeOffSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SetTimeOffDatesContainer = styled.div`
  max-height: 12.5rem;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  margin-top: ${APP.FONT.FONT_SIZE.BODY1};
  padding: 0rem 0.125rem;
`;

export const SetTimeOffDatesSection = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f7d2d2;
  border-radius: 4px;
  height: auto;
  margin-bottom: 0.4rem;
  padding: 0.594rem 1.5rem 0.594rem 0.125rem;
`;

export const DateRangeModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const SetTimeOffDates = styled.p`
  font-weight: bold;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  color: ${APP.FONT.FONT_COLOR.PRIMARY};
`;

export const SetTimeOffEditAndCancelContainer = styled.div`
  display: flex;
`;

export const EditButton = styled(Button)`
  text-transform: capitalize;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  text-decoration: underline;
`;

export const CancelButton = styled(Button)`
  text-transform: capitalize;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  text-decoration: underline;
`;

export const LoadingGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
`;

export const LoadingGridForProfilePic = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 6.55rem;
`;

export const DivContainer = styled.div``;
