import ServiceRequest from 'src/services/ServiceRequest';

import { getAllRolesUrl, getAllTitlesUrl } from './RolesApiEndPoints';

import { GetAllRolesQueryParam, GetAllRolesResDto } from './RolesServiceMapper';
import { CancelTokenSource } from 'axios';

export const getAllTitles = async (
  data?: GetAllRolesQueryParam,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllRolesResDto> => {
  return await ServiceRequest.get(
    getAllTitlesUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

export const getAllRoles = async (
  data?: GetAllRolesQueryParam,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllRolesResDto> => {
  return await ServiceRequest.get(
    getAllRolesUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};
