import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_SortingState as MRTSortingState,
} from 'material-react-table';
import useTranslation from 'i18next';
import { Box, Menu } from '@mui/material';
import { produce } from 'immer';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  CaseNumberTypography,
  CustomerNameTypography,
  DueDateTypography,
  NsaOrStateTypography,
  PayorTypography,
  RowActionsWrapperDiv,
  RowGrid,
  StatusTypography,
  TaskCategoryRowGrid,
  TaskCategoryTypography,
  ViewTextButton,
  ViewTextTypography,
  NoTaskCategoryTypography,
  TaskTypeTypography,
  NoTaskTypeTypography,
  DataNotFoundDiv,
  MoreMenuIconWrapperDiv,
  MenuItemStyled,
  MoreMenuIconImg,
} from './QueueAIStyle';

import ServerSideDataGrid from '@components/custom-data-grid/ServerSideDataGrid';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import {
  ERROR_CONSTANTS,
  SORT_ORDER,
} from '@constants/global-constants/constants';
import { formatDate, statusFormat } from '@modules/home/utils/Utils';
import {
  changeTableName,
  reloadQueueTable,
  setIsRefetchingNeeded,
  setParentQueueTabPathName,
  setShouldFilterActionButtonsDisable,
  setIsFilterResetButtonClicked,
} from '@modules/home/state/queue/QueueSlice';
import { getErrorMessage } from '@utils/ErrorUtils';
import { getAllQueueAIList } from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import {
  AssignedUser,
  QueueAIParams,
} from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import { RootState } from 'src/store/Store';
import moreSvgIcon from '@assets/images/More.svg';
import { QueueTaskStatusTypes } from '../queue-constants/QueueConstants';
import { retryFailedQueueAITask } from 'src/services/service-handlers/private/tasks-handlers/TasksPrivateService';
import { StateNsaAllStateEnum } from 'src/enums/admin-enums/AdminEnums';
import axios, { CancelTokenSource } from 'axios';
import { APP } from 'src/styles/variables';

interface IQueueAIState {
  isQueueAILoading: boolean;
  totalRowCount: number;
  hoveredRow: string;
  disableHoverEffect: boolean;
  networkErrorState: boolean;
  selectedRow: string | null;
  isRetryInProgress: boolean;
}

type QueueAIColumnTypes = {
  assignedUser: null | AssignedUser;
  caseId: string;
  cpt: string;
  customerName: string;
  dueDate: string;
  nsaState: string;
  payorName: string;
  priority: string;
  status: string;
  taskType: string;
  taskCategory: string;
  id: string;
  completedOn: string | null;
  emailDate: string | null;
  stateCode: string | null;
};

const defaultSortOrder: MRTSortingState = [
  {
    id: SORT_ORDER.EMAIL_DATE,
    desc: true,
  },
];

const QueueAI = (): ReactElement => {
  const { t } = useTranslation;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    queueSearchAndFilterOptions,
    isRefetchingNeeded,
    fetchDataFnQueryKeyName,
    isFilterResetButtonClicked,
  } = useSelector((state: RootState) => state.queue);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [queueAiState, setQueueAiState] = useState<IQueueAIState>({
    isQueueAILoading: true,
    totalRowCount: 0,
    hoveredRow: '',
    disableHoverEffect: false,
    networkErrorState: false,
    selectedRow: null,
    isRetryInProgress: false,
  });

  const cancelTokens = useRef<CancelTokenSource[]>([]);

  const fetchData = async (
    pageParam: number,
    fetchSize: number,
    sorting?: MRTSortingState,
  ) => {
    cancelTokens.current.forEach(cancelToken => cancelToken.cancel());
    cancelTokens.current = [];

    const cancelTokenSource = axios.CancelToken.source();
    cancelTokens.current.push(cancelTokenSource);
    if (
      (queueSearchAndFilterOptions &&
        Object.entries(queueSearchAndFilterOptions)?.length) ||
      isFilterResetButtonClicked
    ) {
      dispatch(
        setShouldFilterActionButtonsDisable({
          shouldFilterActionButtonsDisable: true,
        }),
      );
    }

    let searchAndFilterQueries = {};
    const searchAndFilterStateOptions = { ...queueSearchAndFilterOptions };

    if (searchAndFilterStateOptions?.nsaState) {
      if (
        searchAndFilterStateOptions.nsaState ===
        AdminConstants.USERS.NSA.toLowerCase()
      ) {
        searchAndFilterStateOptions.stateNsa =
          searchAndFilterStateOptions.nsaState;
        delete searchAndFilterStateOptions.nsaState;
      } else if (
        searchAndFilterStateOptions.nsaState ===
        AdminConstants.USERS.STATE.toLowerCase()
      ) {
        searchAndFilterStateOptions.stateNsa =
          searchAndFilterStateOptions.nsaState;
        delete searchAndFilterStateOptions.nsaState;
      } else if (searchAndFilterStateOptions.nsaState !== '') {
        searchAndFilterStateOptions.stateNsa =
          AdminConstants.USERS.STATE.toLowerCase();
        searchAndFilterStateOptions.stateId =
          searchAndFilterStateOptions.nsaState;
        delete searchAndFilterStateOptions.nsaState;
      }
    }

    searchAndFilterStateOptions.status = searchAndFilterStateOptions.taskStatus;
    delete searchAndFilterStateOptions.taskStatus;

    if (!searchAndFilterStateOptions.searchValue) {
      delete searchAndFilterStateOptions.searchKey;
    }

    (Object.entries(searchAndFilterStateOptions) as [string, string][]).forEach(
      ([key, value]: [string, string]) => {
        if (value) {
          searchAndFilterQueries = { ...searchAndFilterQueries, [key]: value };
        }
      },
    );

    try {
      const sortOrder =
        sorting && sorting.length > 0 ? sorting[0] : defaultSortOrder[0];

      const payload: QueueAIParams = {
        offset: pageParam * fetchSize,
        limit: fetchSize,
        orderBy: sortOrder.id,
        orderByKey: sortOrder.desc === true ? SORT_ORDER.DESC : SORT_ORDER.ASC,
        ...searchAndFilterQueries,
      };
      const response = await getAllQueueAIList(payload, cancelTokenSource);
      setQueueAiState(
        produce(draft => {
          draft.totalRowCount = response?.data?.count ?? 0;
          draft.networkErrorState = false;
        }),
      );
      return response.data?.rows ?? [];
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      const isNetworkError =
        e.code === ERROR_CONSTANTS.ERR_NETWORK ||
        e.statusCode === 500 ||
        (e.statusCode === 400 && pageParam === 0);

      if (isNetworkError) {
        setQueueAiState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueAiState(
        produce(draft => {
          draft.isQueueAILoading = false;
        }),
      );
      cancelTokens.current = cancelTokens.current.filter(
        token => token !== cancelTokenSource,
      );
      dispatch(
        setShouldFilterActionButtonsDisable({
          shouldFilterActionButtonsDisable: false,
        }),
      );
      dispatch(setIsFilterResetButtonClicked({ isResetButtonClicked: false }));
    }
  };

  useEffect(() => {
    if (isRefetchingNeeded) {
      dispatch(setIsRefetchingNeeded({ data: false }));
      dispatch(changeTableName());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchingNeeded]);

  const init = () => {
    dispatch(reloadQueueTable({ reloadQueueTableGrid: true }));
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueSearchAndFilterOptions]);

  useEffect(() => {
    return () => {
      if (cancelTokens.current.length > 0) {
        cancelTokens.current.forEach(cancelToken => cancelToken.cancel());
        cancelTokens.current = [];
      }
    };
  }, []);

  const queueDetailsNavigationURL = (row: MRTRow<QueueAIColumnTypes>) => {
    dispatch(
      setParentQueueTabPathName({
        parentQueueTabPathNameUrl: location.pathname,
      }),
    );
    return navigate(
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueDetails.FullPath.replace(
        ':taskId',
        row.original.id,
      ),
    );
  };

  const renderNSAStateCellValue = (nsaState: string, stateCode: string) => {
    if (nsaState.toLowerCase() === StateNsaAllStateEnum.NSA) {
      return (
        <RowGrid>
          <NsaOrStateTypography>{nsaState.toUpperCase()}</NsaOrStateTypography>
        </RowGrid>
      );
    } else if (nsaState.toLowerCase() === StateNsaAllStateEnum.STATE) {
      return (
        <RowGrid>
          <NsaOrStateTypography>{stateCode.toUpperCase()}</NsaOrStateTypography>
        </RowGrid>
      );
    } else {
      return (
        <RowGrid>
          <NsaOrStateTypography>{''}</NsaOrStateTypography>
        </RowGrid>
      );
    }
  };

  const columns = useMemo<MRTColumnDef<QueueAIColumnTypes>[]>(
    () => [
      {
        accessorKey: AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.CASE_ID,
        header: t('PRIVATE.QUEUE_AI.QUEUE_AI_TABLE.CASE_NUMBER'),
        accessorFn: row => (
          <RowGrid>
            <CaseNumberTypography>{row?.caseId}</CaseNumberTypography>
          </RowGrid>
        ),
        grow: false,
        size: 150,
      },
      {
        accessorKey: AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.TASK_TYPE,
        header: t('PRIVATE.QUEUE_AI.QUEUE_AI_TABLE.TYPE'),
        accessorFn: row => {
          return (
            <TaskCategoryRowGrid>
              {row?.taskType ? (
                <TaskTypeTypography>{row.taskType}</TaskTypeTypography>
              ) : (
                <NoTaskTypeTypography>-</NoTaskTypeTypography>
              )}
              {row?.taskCategory ? (
                <TaskCategoryTypography>
                  {`(` + row.taskCategory + `)`}
                </TaskCategoryTypography>
              ) : (
                <NoTaskCategoryTypography>-</NoTaskCategoryTypography>
              )}
            </TaskCategoryRowGrid>
          );
        },
        grow: true,
        size: 250,
      },
      {
        accessorKey: AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.CUSTOMER_NAME,
        header: t('PRIVATE.QUEUE_AI.QUEUE_AI_TABLE.CUSTOMER'),
        accessorFn: row => (
          <RowGrid>
            <CustomerNameTypography>{row?.customerName}</CustomerNameTypography>
          </RowGrid>
        ),
        grow: true,
        size: 200,
      },
      {
        accessorKey: AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.NSA_OR_STATE,
        header: t('PRIVATE.QUEUE_AI.QUEUE_AI_TABLE.NSA_OR_State'),
        accessorFn: row =>
          renderNSAStateCellValue(row?.nsaState ?? '', row?.stateCode ?? ''),
        grow: true,
        size: 100,
      },
      {
        accessorKey: AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.PAYOR_NAME,
        header: t('PRIVATE.QUEUE_AI.QUEUE_AI_TABLE.PAYOR'),
        accessorFn: row => (
          <RowGrid>
            <PayorTypography>{row?.payorName}</PayorTypography>
          </RowGrid>
        ),
        grow: true,
        size: 250,
      },
      {
        accessorKey: AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.COMPLETED_ON,
        header: t('PRIVATE.QUEUE_AI.QUEUE_AI_TABLE.COMPLETED_ON_DATE'),
        accessorFn: row => (
          <RowGrid>
            <DueDateTypography>
              {row?.completedOn && formatDate(row.completedOn)}
            </DueDateTypography>
          </RowGrid>
        ),
        grow: true,
        size: 200,
      },
      {
        accessorKey: AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.EMAIL_DATE,
        header: t('PRIVATE.QUEUE_AI.QUEUE_AI_TABLE.EMAIL_DATE'),
        accessorFn: row => (
          <RowGrid>
            <DueDateTypography>
              {row?.emailDate && formatDate(row.emailDate)}
            </DueDateTypography>
          </RowGrid>
        ),
        grow: true,
        size: 200,
      },
      {
        accessorKey: AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.STATUS,
        header: t('PRIVATE.QUEUE_AI.QUEUE_AI_TABLE.STATUS'),
        accessorFn: row => (
          <RowGrid>
            <StatusTypography>{statusFormat(row?.status)}</StatusTypography>
          </RowGrid>
        ),
        grow: true,
        size: 150,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setQueueAiState(
      produce(draft => {
        draft.hoveredRow = '';
        draft.selectedRow = null;
      }),
    );
  };
  const handleRetryClick = async (row: MRTRow<QueueAIColumnTypes>) => {
    setQueueAiState(
      produce(draft => {
        draft.isRetryInProgress = true;
      }),
    );
    try {
      const response = await retryFailedQueueAITask(row.id);
      enqueueSnackbar(response.data.message, SnackBarConfig.getSuccess());
      dispatch(changeTableName());
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      handleCloseMenu();
      setQueueAiState(
        produce(draft => {
          draft.isRetryInProgress = false;
        }),
      );
    }
  };
  const renderMoreMenu = (row: MRTRow<QueueAIColumnTypes>) => {
    return (
      <Menu
        slotProps={{
          paper: {
            style: {
              backgroundColor: APP.PALETTE.COMMON.BLACK,
              width: '12.5rem',
              flexShrink: 0,
            },
          },
        }}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onMouseLeave={() => {
          setMenuAnchorEl(null);
        }}
        onClose={handleCloseMenu}
      >
        <MenuItemStyled
          sx={{ borderBottom: 'none !important' }}
          onClick={() => handleRetryClick(row)}
        >
          {t('PRIVATE.QUEUE_AI.RETRY')}
        </MenuItemStyled>
      </Menu>
    );
  };
  const handleMoreClick = (
    event: React.MouseEvent<HTMLDivElement>,
    rowId: string,
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setQueueAiState(
      produce(draft => {
        draft.selectedRow = rowId;
      }),
    );
  };

  const renderRowActionsSection = (row: MRTRow<QueueAIColumnTypes>) => {
    return (
      <RowActionsWrapperDiv>
        {queueAiState.hoveredRow ===
          (row?.original?.caseId || '') + (row?.original?.id || '') && (
          <>
            <ViewTextButton
              variant="outlined"
              onClick={() => queueDetailsNavigationURL(row)}
            >
              <ViewTextTypography>
                {t('PRIVATE.QUEUE_AI.VIEW')}
              </ViewTextTypography>
            </ViewTextButton>
            {QueueTaskStatusTypes.FAILED === row?.original?.status && (
              <MoreMenuIconWrapperDiv
                data-row-id={row.id}
                onClick={e => handleMoreClick(e, row.id)}
              >
                <MoreMenuIconImg src={moreSvgIcon} alt="more-icon" />
              </MoreMenuIconWrapperDiv>
            )}
            {queueAiState.selectedRow === row.id &&
              QueueTaskStatusTypes.FAILED === row?.original?.status &&
              renderMoreMenu(row)}
          </>
        )}
      </RowActionsWrapperDiv>
    );
  };

  const renderQueueAITable = (): ReactElement => {
    return queueAiState.networkErrorState ? (
      <DataNotFoundDiv>{t('NO_DATA_FOUND')}</DataNotFoundDiv>
    ) : (
      <ServerSideDataGrid
        fetchDataFnQueryKeyName={fetchDataFnQueryKeyName}
        columns={columns}
        fetchDataFn={fetchData}
        dataFetchSize={AdminConstants.QUEUE_AI.QUEUE_TABLE_DATA_LIMIT}
        enableRowSelection={false}
        muiTableBodyRowProps={({ row }) => ({
          onMouseEnter: () => {
            if (queueAiState.disableHoverEffect) return;
            setQueueAiState(
              produce(draft => {
                draft.hoveredRow =
                  (row?.original?.caseId || '') + (row?.original?.id || '');
              }),
            );
          },
          onMouseLeave: () => {
            if (queueAiState.disableHoverEffect) return;
            setQueueAiState(
              produce(draft => {
                draft.hoveredRow = ' ';
              }),
            );
          },
        })}
        enableSortingRemoval={false}
        enableSorting={true}
        totalRowsCount={queueAiState.totalRowCount}
        getRowId={row => row?.id || ''}
        state={{
          isLoading:
            queueAiState.isQueueAILoading || queueAiState.isRetryInProgress,
        }}
        enableRowActions={true}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 140,
            grow: false,
            header: '',
          },
        }}
        renderRowActions={({ row }) => renderRowActionsSection(row)}
        enableGlobalFilter
        isUpdatedDataLoading={queueAiState.isRetryInProgress}
        muiCircularProgressProps={{
          color: 'secondary',
          thickness: 5,
          size: 55,
        }}
      />
    );
  };

  return <Box>{renderQueueAITable()}</Box>;
};

export default QueueAI;
